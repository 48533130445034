import { useEffect, useState, useMemo } from 'react'

const useScroll = () => {
  const [scrolled, setScrolled] = useState(false)
  const [scrolledToTop, setScrolledToTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const newY = window.pageYOffset
      const scrolled = newY > 0
      const scrolledToTop = newY <= 0
      setScrolled(scrolled)
      setScrolledToTop(scrolledToTop)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const scrollData = useMemo(() => ({ scrolled, scrolledToTop }), [scrolled, scrolledToTop])

  return scrollData
}

export default useScroll
