import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Icon } from '../Icon/Icon'
import { TypeButton, TypeNavigationMenu, TypeResourceSet } from '../../types/contentful'
import MenuGroup from './MenuGroup'
import { isContentfulType } from '../../lib/contentful'
import SocialIcons from './SocialIcons'
import Link from 'next/link'

type MenuBarProps = {
  open: boolean
  onClose: () => void
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  activeSlug?: string
  hoverColor?: string
  sideMenuTopIcon?: TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const SPEED = 300

const MenuBar = ({
  open,
  onClose,
  menu,
  hoverColor,
  activeSlug,
  sideMenuTopIcon,
}: MenuBarProps) => {
  const [lastOpen, setLastOpen] = useState<boolean>(open)
  const [animating, setAnimating] = useState<boolean>(false)
  const animate = useDebouncedCallback(() => setAnimating(false), SPEED)
  useEffect(() => {
    if (lastOpen !== open) {
      setAnimating(true)
      animate()
      setLastOpen(open)
    }
  }, [open, lastOpen, animate])

  const handleClose = () => {
    onClose()
  }

  // Styles for shade
  const opacityClass = open ? 'opacity-30' : 'opacity-0'
  const hiddenClass = !animating && !open ? 'hidden' : ''
  const shadeClass = `${hiddenClass} ${opacityClass}`
  const menuClass = open ? 'left-0' : 'md:-left-sidebar !-left-screen'
  const resources = [...(menu?.fields.resources || [])]
  const social = resources.pop()

  return (
    <>
      <div
        className={`fixed left-0 top-0 right-0 bottom-0 bg-primary-ui-black z-[1000] ${shadeClass}`}
        onClick={handleClose}
      ></div>
      <nav
        className={`
        md:w-sidebar w-screen fixed left-0 top-0 bottom-0
        scroll-auto transition-width duration-300 bg-light-navbar dark:bg-dark-navbar z-[1001] flex flex-col
        ${menuClass}
      `}
      >
        <div
          className={`px-5 py-[1.4rem] border-secondary-ui-200 border-b flex flex-row justify-between`}
        >
          <button onClick={handleClose} className={`block`}>
            <Icon
              name={'Close'}
              className={`w-4 h-64cursor-pointer text-dark-navbar dark:text-light-navbar hover:text-secondary-ui-600`}
            />
          </button>

          {sideMenuTopIcon && (
            <Link href={sideMenuTopIcon?.fields?.url} onClick={handleClose}>
              <Icon
                name={sideMenuTopIcon?.fields?.icon}
                className={`w-5 h-5 cursor-pointer text-dark-navbar dark:text-light-navbar hover:text-secondary-ui-600`}
              />
            </Link>
          )}
        </div>
        <div className="overflow-scroll text-dark-navbar dark:text-light-navbar mb-16">
          {resources.map((menuItem, index) => {
            if (
              isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                menuItem,
                'navigationMenu'
              )
            ) {
              return menuItem?.fields?.title !== 'Top navigation' ? (
                <MenuGroup
                  key={`${menuItem.sys.id}-${index}`}
                  menu={menuItem}
                  handleClose={handleClose}
                  activeSlug={activeSlug}
                  hoverColor={hoverColor}
                  type="menu"
                />
              ) : (
                <></>
              )
            }
          })}
        </div>
        {isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          social,
          'navigationMenu'
        ) && (
          <div className="absolute w-full m-auto bottom-0 text-dark-navbar pt-3 dark:text-light-navbar bg-light-navbar dark:bg-dark-navbar">
            <SocialIcons menu={social} />
          </div>
        )}
      </nav>
    </>
  )
}

export default MenuBar
