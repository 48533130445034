import {
  TypeButton,
  TypeImageWithFocalPoint,
  TypePerson,
  TypeTag,
  TypeMarketoFormButtonBlock,
} from '../../types/contentful'
import Link from 'next/link'
import Image from 'next/legacy/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import classnames from 'classnames'
import React, { useMemo, useState } from 'react'
import { getShapeClass } from '../Shapes/Shapes'
import { CardShape, CardSize } from '../../types/content'
import { renderCTAButtons } from '../CTAButtons/CTAButtons'
import { isContentfulType } from '../../lib/contentful'
import Button from '../Button/Button'
import { date } from '../../lib/date'
import Quote from '../Icons/Quote'
import { CourseIntake } from '../../types/graphql'
import { Document, Text } from '@contentful/rich-text-types/dist/types/index'
import { DualPrices } from '../CTACard/CTACardPrice'
import { CoursePriceType } from '../../types/types'
import { Icon } from '../Icon/Icon'

type CardV2PropTypes = {
  title: string
  subtitle?: string
  description?: string
  className?: string
  slug?: string
  author?: TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
  readTime?: number
  createdAt?: string
  size?: CardSize
  thumbnailWithFocalPoint?: TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
  cardType?: 'Event' | 'Article' | 'Course' | 'Person'
  tags?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>[]
  priceFree?: boolean
  prices?: DualPrices
  currency?: string
  timeframe?: string
  credits?: number
  time?: string
  location?: string
  shape?: CardShape
  thumbnailUrl?: string
  type?: 'Default' | 'Description' | 'Promotion'
  cornersStyle?: 'Rounded' | 'Square'
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  isComingSoon?: boolean
  backgroundColor?: string
  textColor?: string
  durationDescription?: string
  timeframeIcon?: string
  durationDescriptionIcon?: string
  content?: Document
  intakes?: CourseIntake[]
  startType?: string
  onCardClick?: () => void
}

export const contentfulType = {
  Default: 'Default',
  Description: 'Description',
}

/* function getNZQALevel(durationDescription: string) {
  const level = durationDescription?.split('NZQA level')
  return level?.[1]
}

function getMonths(timeframe: string) {
  const time = timeframe?.split(' ')
  if (time?.[1]?.toLowerCase()?.includes('year')) {
    return +time?.[0] * 12
  } else {
    return Math.ceil(+time?.[0] / 4.34524)
  }
} */

function getEarlyBirdPrice(price: string) {
  const priceNumber = price?.replace(/[^0-9]/g, '')
  const discountedPrice = Math.ceil(+priceNumber * 0.75)
  if (Number(discountedPrice)) {
    const earlyBirdPrice = discountedPrice?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    })
    return earlyBirdPrice
  }
  return null
}

const CardV2 = ({
  title,
  subtitle,
  description,
  slug,
  onCardClick,
  // author,
  // createdAt,
  size = 'Small Square',
  thumbnailWithFocalPoint,
  thumbnailUrl,
  cardType,
  priceFree,
  prices,
  // timeframe,
  credits,
  shape,
  type = 'Default',
  cornersStyle,
  button,
  isComingSoon = false,
  // durationDescription,
  content,
  intakes,
  startType,
}: CardV2PropTypes) => {
  // const createdAtDate = createdAt ? dayjs(createdAt).format('MMM DD, YYYY') : null
  const thumbnailFocalRatio = getFocalRatio(thumbnailWithFocalPoint?.fields)
  const [showMore, setShowMore] = useState(false)
  const filteredIntakes = intakes?.filter(
    intake => !intake.courseEdition.unavailableForInternational
  )
  const displayShowMore = useMemo(() => filteredIntakes?.length > 3, [filteredIntakes])
  const handleClose = () => {
    setShowMore(false)
  }

  /* const typeData = {
    Event: {
      label: 'Event',
      slug: '/browse/events',
    },
    Course: {
      label: 'Learn',
      slug: '/browse/courses',
    },
    Article: {
      label: 'Reading',
      slug: `/browse/stories`,
    },
    Person: {
      label: 'People',
      slug: '/browse/people',
    },
  } */

  const cardStyle = classnames(
    size === 'Medium Wide' || size === 'Large Square' ? 'sm:col-span-2' : ''
  )

  const imageStyle = classnames(
    'relative block w-full mb-6',
    getShapeClass(size, shape),
    size === 'Medium Tall' || size === 'Large Square' ? 'h-80 sm:h-[40rem]' : 'h-80'
  )

  /* const tagStyle = classnames(
    type === 'Promotion' && cornersStyle === 'Square' ? '' : 'rounded-md '
  ) */

  // const image = getImageWithFocalPoint(author?.fields?.hero)
  // const authorPhotoFocalRatio = getFocalRatio(image?.fields)

  if (
    isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(button, 'button') &&
    !button.fields.url
  ) {
    const new_button = button
    new_button.fields.url = slug
  }

  if (cardType === 'Course') {
    const chineseTitle = (() => {
      switch (title) {
        case 'Master of Technological Futures':
          return '混合领域硕士——科技未来管理'
        case 'Master of Contemporary Education':
          return '教育学硕士——当代教育创新与领导力'
        case 'Master of Change and Organisational Resilience':
          return '管理学硕士——变革与组织应变力'
        default:
          return ''
      }
    })()

    const chineseDescription = (() => {
      switch (title) {
        case 'Master of Technological Futures':
          return `新西兰最具创新性和前瞻性的硕士课程，旨在提升专业人士的技能，充分利用快速崛起的数字时代所提供的机遇。
          该课程与行业紧密合作，由领先的技术专家和社会企业领袖授课，面向受新兴技术影响的技术和商业专业人士，以及希望在这个背景下重新规划职业的人士。
          通过这个实用而非传统的课程，学员将成为把握利用未来科技的专家，为行业和社会带来巨大的价值。
          全日制课程时长︰14-18个月（${credits}学分）`
        case 'Master of Contemporary Education':
          return `本课程旨在支持教师和教育领域工作者在不断变化的教学和学习环境中的专业发展和需求。
          该硕士项目直接关注于为小学、中学和大学的学习者、学校和教育社区增加可衡量、有益价值。课程侧重于教育学科，结合理论和实践，提供教育工作者所需的知识和技能，以提高他们的职业素养，为他们的学生和教育社区做出实际的贡献。
          毕业生将成为教育创新的领导者，能够不断推动教育进步。
          全日制课程时长︰18-24个月（${credits}学分）`
        case 'Master of Change and Organisational Resilience':
          return `《变革和组织应变力硕士》课程旨在培养学生（特别是商业和社区领袖）建立协作工作关系的技能，以支持他们在快速变化的环境中取得成功。
          学生将应用面向未来的应变力框架来解决其专业环境中的实际问题，同时考虑对人、地区和全球的影响。
          此外，课程强调在追求组织韧性的同时，增加对学生、学校以及社区的可衡量和有益价值。
          全日制课程时长︰14-18个月（${credits}学分）`
        default:
          return ''
      }
    })()

    const chineseDescriptionArray = chineseDescription?.split('\n')
    // const timeframeInMonths = getMonths(timeframe)
    const onshoreEarlyBirdPrice =
      prices?.[1] && prices?.[1].location === CoursePriceType.ONSHORE
        ? getEarlyBirdPrice(prices?.[1]?.price as string)
        : ''
    const onlineEarlyBirdPrice =
      prices?.[0] && prices?.[0].location === CoursePriceType.ONLINE
        ? getEarlyBirdPrice(prices?.[0]?.price as string)
        : ''

    return (
      <div>
        <Link href={slug} className={cardStyle} onClick={onCardClick}>
          {/* Start Thumbnail */}
          {(thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url || thumbnailUrl) && (
            <div className={imageStyle}>
              {isComingSoon && (
                <div
                  className={`absolute z-10 right-3 top-3 bg-secondary-600 px-3 py-1 bg-[#E0E0E0] ${
                    cornersStyle === 'Square' ? '' : 'rounded-lg'
                  }`}
                >
                  coming soon
                </div>
              )}
              <Image
                className={`duration-300 hover:scale-105 transition`}
                src={
                  thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url
                    ? `https:${thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url}`
                    : thumbnailUrl
                }
                alt={
                  thumbnailWithFocalPoint?.fields?.image?.fields?.description ||
                  thumbnailWithFocalPoint?.fields?.image?.fields?.title ||
                  title
                }
                layout="fill"
                objectFit="cover"
                objectPosition={`${thumbnailFocalRatio.x}% ${thumbnailFocalRatio.y}%`}
                sizes={size === 'Small Square' ? '40rem' : '80rem'}
              />
            </div>
          )}
          {/* End Thumbnail */}
          {
            <div className="flex flex-col text-center lg:h-32 xl:h-20">
              {/* Title for Default */}
              <div className="font-primary text-lg font-bold leading-8">{chineseTitle}</div>
              <div className="font-primary text-sm leading-5">{title}</div>
              <div className="font-primary text-sm leading-5">毕业后获得新西兰NZQA 9级硕士学位</div>
              {/* Subtitle */}
              {!!subtitle && <span className="font-primary text-x">{subtitle}</span>}
            </div>
          }
          <hr className="my-3" />
          {/* Description */}
          {description && (
            <>
              <div className="text-secondary-ui-700 text-sm ml-1 leading-4 min-h-[12rem] md:h-52 lg:h-[17rem] xl:h-56">
                {chineseDescriptionArray.map((item, index) => {
                  return <p key={index}>{item}</p>
                })}
              </div>
              <hr className="my-3" />
            </>
          )}
        </Link>
        {displayShowMore && !showMore && filteredIntakes && startType === 'Fixed' && (
          <>
            <div
              className={`flex flex-col min-h-[6rem] md:min-h-[7rem] lg:min-h-[9rem] xl:min-h-[7rem]`}
            >
              <p className="font-bold ml-4 text-sm leading-5 mb-0">入学时间：</p>
              <div className="ml-4 text-sm leading-4 mb-0">
                {filteredIntakes.slice(0, 3).map((intake, index) => {
                  const reversedIndex = filteredIntakes.length - index - 1
                  const intakeDate = date(
                    filteredIntakes[reversedIndex].courseEdition.dateStart
                  ).format('D M YYYY')
                  const intakeDateArray = intakeDate?.split(' ')
                  return (
                    <span key={index} className="mb-0">{`${index !== 0 ? '，' : ''}${
                      intakeDateArray[2]
                    }年${intakeDateArray[1]}月${intakeDateArray[0]}日`}</span>
                  )
                })}
                <span className="mb-0">（新西兰境内全职学习）</span>
              </div>
              {filteredIntakes.some(intake => intake.dateEnd) && (
                <div className="ml-4 text-sm leading-4 mb-0">
                  <span className="mb-0">申请截止日期：</span>
                  {filteredIntakes.slice(0, 3).map((intake, index) => {
                    const reversedIndex = filteredIntakes.length - index - 1

                    const intakeDate = date(
                      filteredIntakes[reversedIndex].courseEdition.dateStart
                    ).format('D M YYYY')
                    const intakeDateArray = intakeDate?.split(' ')
                    const endDate = date(filteredIntakes[reversedIndex].dateEnd).format('D M YYYY')
                    const endDateArray = endDate?.split(' ')
                    return (
                      filteredIntakes[reversedIndex].dateEnd && (
                        <span key={index} className="mb-0">{`${index !== 0 ? '，' : ''}${
                          intakeDateArray[1]
                        }月入学为${endDateArray[1]}月${endDateArray[0]}日`}</span>
                      )
                    )
                  })}
                </div>
              )}

              {displayShowMore && (
                <span
                  className={
                    'cursor-pointer text-accent-4-700 text-sm flex flex-row gap-1 items-center mt-1 ml-4'
                  }
                  onClick={() => setShowMore(!showMore)}
                >
                  显示更多
                  <Icon className={'h-5 w-5 mb-1'} name={'ChevronDownIcon'} />
                </span>
              )}
            </div>

            <hr className="my-3" />
          </>
        )}

        {(!displayShowMore || showMore) && filteredIntakes && startType === 'Fixed' && (
          <>
            <div
              className={`flex flex-col min-h-[6rem] md:min-h-[7rem] lg:min-h-[9rem] xl:min-h-[7rem]`}
            >
              <p className="font-bold ml-4 text-sm leading-5 mb-0">入学时间：</p>
              <div className="ml-4 text-sm leading-4 mb-0">
                {filteredIntakes.map((intake, index) => {
                  const reversedIndex = filteredIntakes.length - index - 1
                  const intakeDate = date(
                    filteredIntakes[reversedIndex].courseEdition.dateStart
                  ).format('D M YYYY')
                  const intakeDateArray = intakeDate?.split(' ')
                  return (
                    <span key={index} className="mb-0">{`${index !== 0 ? '，' : ''}${
                      intakeDateArray[2]
                    }年${intakeDateArray[1]}月${intakeDateArray[0]}日`}</span>
                  )
                })}
                <span className="mb-0">（新西兰境内全职学习）</span>
              </div>
              {filteredIntakes.some(intake => intake.dateEnd) && (
                <div className="ml-4 text-sm leading-4 mb-0">
                  <span className="mb-0">申请截止日期：</span>
                  {filteredIntakes.map((intake, index) => {
                    const reversedIndex = filteredIntakes.length - index - 1

                    const intakeDate = date(
                      filteredIntakes[reversedIndex].courseEdition.dateStart
                    ).format('D M YYYY')
                    const intakeDateArray = intakeDate?.split(' ')
                    const endDate = date(filteredIntakes[reversedIndex].dateEnd).format('D M YYYY')
                    const endDateArray = endDate?.split(' ')
                    return (
                      filteredIntakes[reversedIndex].dateEnd && (
                        <span key={index} className="mb-0">{`${index !== 0 ? '，' : ''}${
                          intakeDateArray[1]
                        }月入学为${endDateArray[1]}月${endDateArray[0]}日`}</span>
                      )
                    )
                  })}
                </div>
              )}
              {displayShowMore && (
                <a
                  className={
                    'cursor-pointer text-accent-4-700 text-sm flex flex-row gap-1 items-center mt-1 ml-4'
                  }
                  onClick={() => handleClose()}
                >
                  显示较少
                  <Icon className={'h-5 w-5 mb-1'} name={'ChevronUpIcon'} />
                </a>
              )}
            </div>

            <hr className="my-3" />
          </>
        )}

        {/* {(timeframe || credits) && (
              <>
                <div className="ml-4">
                  <span className="font-bold text-sm leading-5">课程时长：</span>
                  <span className="text-sm leading-4">{timeframeInMonths}个月</span>
                  {credits && <span className="text-sm leading-4">（{credits}学分）</span>}
                </div>
                <hr className="my-3" />
              </>
            )} */}
        {/* End Course Info */}

        {prices.length > 0 && (
          <div className="ml-4">
            <div className="font-bold text-sm leading-5">国际留学生学费:</div>
            <div className="flex flex-row gap-2">
              <div className="text-sm leading-4 w-full mb-0">
                境内课早鸟价
                <p className="text-xl mr-1 font-bold mb-0">
                  {onshoreEarlyBirdPrice} <span className="text-sm font-normal">USD</span>
                  {/* This is the early-bird-ised onshore price */}
                </p>
                <p className="text-sm text-[#595959] my-0 leading-3 line-through">
                  {(prices?.[1]?.price as string) || ''}{' '}
                  <span className="text-sm font-normal">USD</span>
                  {/* This is the onshore price */}
                </p>
              </div>
              <div className="text-sm leading-4 w-full mb-0">
                网上课程早鸟价
                <p className="text-xl mr-1 font-bold mb-0">
                  {onlineEarlyBirdPrice} <span className="text-sm font-normal">USD</span>
                  {/* This is the early-bird-ised online price */}
                </p>
                <p className="text-sm text-[#595959] my-0 leading-3 line-through">
                  {(prices?.[0]?.price as string) || ''}{' '}
                  <span className="text-sm font-normal">USD</span>
                  {/* This is the online price */}
                </p>
              </div>
            </div>
            <p className="text-sm leading-4 pt-1 mb-0">*课程开始两个月或之前报名</p>
          </div>
        )}

        {priceFree && (
          <div className="ml-4">
            <div className="font-bold text-sm leading-5">国际留学生学费:</div>
            <p className="text-xl mr-1 font-bold mb-0 pt-1">免费</p>
          </div>
        )}
        <hr className="my-3" />

        {button && renderCTAButtons({ buttons: [button], slug })}
        <div className="flex justify-center">
          <Button buttonStyle="outline" className="my-4" href={slug}>
            {'立 刻 报 名'}
          </Button>
        </div>
      </div>
    )
  }

  if (cardType === 'Person') {
    return (
      <div>
        <Link href={slug} className={cardStyle} onClick={onCardClick}>
          {/* Start Thumbnail */}
          {(thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url || thumbnailUrl) && (
            <div className={imageStyle}>
              <Image
                className={`duration-300 hover:scale-105 transition ${
                  cornersStyle === 'Rounded' ? 'rounded-lg' : ''
                }`}
                src={
                  thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url
                    ? `https:${thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url}`
                    : thumbnailUrl
                }
                alt={
                  thumbnailWithFocalPoint?.fields?.image?.fields?.description ||
                  thumbnailWithFocalPoint?.fields?.image?.fields?.title ||
                  title
                }
                layout="fill"
                objectFit="cover"
                objectPosition={`${thumbnailFocalRatio.x}% ${thumbnailFocalRatio.y}%`}
                sizes={size === 'Small Square' ? '40rem' : '80rem'}
              />
            </div>
          )}
          {/* End Thumbnail */}
          {type === 'Promotion' && (
            <div className="flex flex-col mb-4">
              {/* Title for Default */}
              <div className="flex flex-row font-primary text-2xl justify-between">
                {title}
                <Quote color="black" className="scale-x-[-1] scale-y-[-1]" />
              </div>
              {/* Subtitle */}
              {!!subtitle && <span className="font-primary text-sm font-bold">{subtitle}</span>}
            </div>
          )}
          {/* Description */}
          {description && (
            <div className="text-secondary-ui-700 font-medium text-sm flex flex-row">
              <p className="w-[20%] m-0 font-bold">所学专业：</p>
              <p className="m-0 mb-4 w-[80%]">{description}</p>
            </div>
          )}
          {content?.content &&
            content?.content.map((item, index) => {
              return (
                <p key={index} className="text-secondary-ui-700 font-medium text-sm">
                  {(item.content[0] as Text)?.value}
                </p>
              )
            })}
        </Link>
        {type === 'Promotion' && button && renderCTAButtons({ buttons: [button], slug })}
      </div>
    )
  }
}

export default CardV2
