import React, { useCallback, useState } from 'react'
import { TypeGridContentBlock } from '../../types/contentful'
import { EmbeddedEntry } from '../EmbeddedEntry/EmbeddedEntry'
import classnames from 'classnames'
import { Icon } from '../Icon/Icon'
import { GridContentBlockMobileDisplay } from '../../types/content'

type GridContentBlockProps = {
  entry: TypeGridContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const itemsToShow = 3
const loadStep = 3

const GridContentBlock = ({ entry }: GridContentBlockProps) => {
  const {
    fields: { maxColumns: contentfulMaxColumns = 1, items, mobileDisplay },
    sys: { id },
  } = entry

  const [visibleItemCount, setVisibleItemCount] = useState(itemsToShow)

  const handleViewMore = useCallback(() => {
    setVisibleItemCount(prev => prev + loadStep)
  }, [loadStep])

  const maxColumns = Math.min(contentfulMaxColumns, items.length) || 1

  // Declare all valid column classes to tailwind
  const columnClass = [
    'md:grid-cols-1 gap-x-1',
    'md:grid-cols-2 gap-5',
    'md:grid-cols-3 gap-x-3',
    'md:grid-cols-4 gap-x-4',
    'md:grid-cols-5 gap-x-5',
    'md:grid-cols-6 gap-x-6',
  ][maxColumns - 1]

  return (
    <>
      {mobileDisplay === GridContentBlockMobileDisplay.ThreeAtATime && (
        <div className={classnames('md:hidden relative grid grid-cols-1 pb-6', columnClass)}>
          {items &&
            items
              .slice(0, visibleItemCount)
              .map((item, index) => <EmbeddedEntry key={`${id}-${index}`} entry={item} />)}
          <div className="flex flex-row justify-center">
            {visibleItemCount < items.length && (
              <div
                className="flex justify-center items-center gap-2 text-secondary-ui-600"
                onClick={handleViewMore}
              >
                <span>Show more</span>
                <Icon name={'ToggleDown'} />
              </div>
            )}
          </div>
        </div>
      )}

      {/* Desktop Version */}
      <div
        className={classnames(
          `${mobileDisplay ? 'hidden relative md:grid' : 'grid'} mb-10 grid-cols-1`,
          columnClass
        )}
      >
        {items && items.map((item, index) => <EmbeddedEntry key={`${id}-${index}`} entry={item} />)}
      </div>
    </>
  )
}

export default GridContentBlock
