import React, { useState } from 'react'
import Button, { ButtonPropTypes } from '../Button/Button'
import { HubspotEmbeddedForm } from '../MarketoFormButtonBlock/HubspotEmbeddedForm'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Icon } from '../Icon/Icon'

interface HubspotFormButtonBlockProps {
  portalId: string
  formId: string
  title: string
  buttonLabel: string
  buttonColor: string
  style: ButtonPropTypes['style']
  size: ButtonPropTypes['size']
  icon?: string
  textColor?: string
  hideIconMobile?: boolean
  textColour?: string
  buttonHoverColor?: string
}

const HubspotFormButtonBlock = ({
  portalId,
  formId,
  title,
  buttonLabel,
  buttonColor,
  style,
  size,
  icon,
  hideIconMobile,
  textColor,
  buttonHoverColor,
}: HubspotFormButtonBlockProps) => {
  const [showModal, setShowModal] = useState(false)

  const handleClick = () => {
    setShowModal(true)
  }

  return (
    <>
      <Button
        id={formId}
        buttonStyle={style}
        size={size}
        onClick={handleClick}
        backgroundColor={buttonColor}
        hoverColor={buttonHoverColor}
        textColor={textColor}
      >
        <div className={`${hideIconMobile ? 'hidden md:block' : ''}`}>
          <Icon className="w-5 h-5 mr-2" name={icon} />
        </div>
        {buttonLabel}
      </Button>

      <div
        className={`${
          !showModal ? 'hidden' : 'flex'
        } z-[200] fixed left-0 right-0 top-0 bottom-0 flex flex-col justify-center items-center bg-[rgba(0,0,0,0.8)] `}
      >
        <div className="md:w-[35rem] m-6 bg-white p-6 animate-fade-down overflow-scroll rounded-xl">
          <div className="flex justify-end">
            <button onClick={() => setShowModal(false)}>
              <XCircleIcon className="w-6 h-6 text-black ml-20" />
            </button>
          </div>
          <div className="overflow-scroll h-full w-full">
            <HubspotEmbeddedForm portalId={portalId} formId={formId} title={title} isModal />
          </div>
        </div>
      </div>
    </>
  )
}

export default HubspotFormButtonBlock
