import { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

type FullscreenProps = {
  children: ReactNode
}

const Fullscreen = ({ children }: FullscreenProps) => {
  const ref = useRef<HTMLBodyElement>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = window.document.getElementsByTagName('body')?.[0]
    setMounted(true)
  }, [])

  return mounted ? createPortal(children, ref.current) : null
}

export default Fullscreen
