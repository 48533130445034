import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import React, { useMemo } from 'react'
import { TypeButton } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import Container from '../Container/Container'

type ButtonBlockProps = {
  entry: TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
}
const ButtonBlock = ({ entry }: ButtonBlockProps) => {
  const buttonPosition = useMemo(() => {
    switch (entry?.fields?.align) {
      case 'Left':
        return 'justify-start'
      case 'Center':
        return 'justify-center'
      case 'Right':
        return 'justify-end'
      default:
        return 'justify-start'
    }
  }, [entry?.fields?.align])
  return (
    <Container className={`flex ${buttonPosition}`}>
      <Button
        id={entry?.sys?.id}
        href={entry?.fields?.url}
        buttonStyle={contentfulType[entry?.fields?.style]}
        size={contentfulSize[entry?.fields?.size]}
        variant={contentfulVariant[entry?.fields?.variant]}
        urlBehaviour={entry?.fields?.urlBehaviour}
        className="mb-4"
      >
        {entry.fields.align !== 'Right' && entry?.fields?.icon && entry?.fields?.icon && (
          <Icon name={entry?.fields?.icon} className="mr-1 w-4 h-4" />
        )}
        {entry?.fields?.label}
        {entry.fields.align === 'Right' && entry?.fields?.icon && entry?.fields?.icon && (
          <Icon name={entry?.fields?.icon} className="ml-1 w-4 h-4" />
        )}
      </Button>
    </Container>
  )
}

export default ButtonBlock
