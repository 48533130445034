import TagManager from 'react-gtm-module'
import { ProductList } from './model/product-list.model'
import { Product } from './model/product.model'
import { Transaction } from './model/transaction.model'

/**
 * Clear the previous ecommerce object
 */
const clearProducts = () => {
  const data = {
    dataLayer: {
      ecommerce: null,
    },
  }
  TagManager.dataLayer(data)
}

export const setDisplayedProducts = (list: ProductList, currency: string, items: Product[]) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'view_item_list',
      ecommerce: {
        currency,
        ...list,
        items,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const setSelectedProduct = (list: ProductList, currency: string, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'select_item',
      ecommerce: {
        ...list,
        currency,
        items: [item],
      },
    },
  }
  TagManager.dataLayer(data)
}

export const setViewedPage = (pageName: string, id: string, version: string) => {
  if (pageName === '/' || pageName === 'v2') {
    pageName = 'home'
  }

  const data = {
    dataLayer: {
      event: `view_${pageName}_page`,
      ABVariant: {
        version,
      },
    },
  }
  TagManager.dataLayer(data)
}

export const setViewedProduct = (list: ProductList, currency: string, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        ...list,
        currency,
        items: [item],
      },
    },
  }
  TagManager.dataLayer(data)
}

export const checkoutStart = (list: ProductList, transaction: Transaction, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
      },
    },
  }
  TagManager.dataLayer(data)
}

export const checkoutAddPaymentInfo = (
  list: ProductList,
  transaction: Transaction,
  item: Product
) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
      },
    },
  }
  TagManager.dataLayer(data)
}

export const checkoutPurchase = (list: ProductList, transaction: Transaction, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
      },
    },
  }
  TagManager.dataLayer(data)
}

export const checkoutRefund = (list: ProductList, transaction: Transaction, item: Product) => {
  clearProducts()
  const data = {
    dataLayer: {
      event: 'refund',
      ecommerce: {
        ...list,
        ...transaction,
        items: [item],
      },
    },
  }
  TagManager.dataLayer(data)
}
