import React from 'react'
import FooteFooterDefault from './FooterDefault'
import FooterV2 from './FooterV2'

import { TypeResourceSet } from '../../types/contentful'

type FooterProps = {
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  colour?: string
  bgColourClass?: string
  customClass?: string
}

const Footer = ({ menu, colour, bgColourClass, customClass }: FooterProps) => {
  return (
    <>
      {menu?.fields.variation !== 'V2' && (
        <FooteFooterDefault
          menu={menu}
          colour={colour}
          bgColourClass={bgColourClass}
          customClass={customClass}
        />
      )}
      {menu?.fields.variation === 'V2' && (
        <FooterV2
          menu={menu}
          colour={colour}
          bgColourClass={bgColourClass}
          customClass={customClass}
        />
      )}
    </>
  )
}

export default Footer
