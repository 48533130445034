import Link from 'next/link'
import React from 'react'
import { TypeNavigationItem } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import classnames from 'classnames'
import { ButtonURLBehaviours as URLBehaviours } from '../../types/content'

type MenuLinkProps = {
  item: TypeNavigationItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
  handleClose?: () => void
  activeSlug?: string
  type?: 'footer' | 'menu' | 'navbar'
  hoverColor?: string
}

const MenuLink = ({ item, handleClose, activeSlug, type = 'menu', hoverColor }: MenuLinkProps) => {
  const cssVars = {
    '--navbar-hover': `${hoverColor}`,
  } as React.CSSProperties

  const active = 'font-semibold text-accent-4-700'
  const refStyles = classnames(
    `${type === 'footer' ? 'mt-[0.2rem]' : 'whitespace-nowrap w-full px-3 mx-3 flex'} ${
      type === 'menu' && 'py-1 my-2'
    } items-center`,
    activeSlug === item.fields.link && active,
    `/${activeSlug}` === item.fields.link && active,
    activeSlug === '/' && item.fields.link === `${process.env.NEXT_PUBLIC_BASE_URL}/` && active,
    `${
      type === 'menu'
        ? 'hover:bg-hover-color hover:rounded-md hover:text-accent-4-700 bg-clip-border'
        : ''
    }`
  )

  const icon = item?.fields?.icon && (
    <div className="mr-2">
      <Icon name={item.fields.icon} className="w-5 h-5" />
    </div>
  )

  if (item?.fields?.link?.startsWith('http')) {
    return (
      <a
        id={item.sys.id}
        href={item.fields.link}
        target={item.fields.urlBehaviour === URLBehaviours.NewTab ? '_blank' : '_self'}
        rel="external noopener noreferrer"
        className={`whitespace-nowrap ${type === 'footer' ? 'mt-[0.2rem]' : ''} ${
          type === 'menu' && refStyles
        }`}
        style={cssVars}
      >
        {type !== 'footer' && icon}

        <span className="pt-[0.15rem]"> {item.fields.title}</span>
      </a>
    )
  }

  return item?.fields?.link ? (
    <Link
      href={item.fields.link.startsWith('/') ? item.fields.link : `/${item.fields.link}`}
      id={item.sys.id}
      onClick={handleClose}
      className={refStyles}
      style={cssVars}
    >
      {type !== 'footer' && icon}
      <span className="pt-[0.15rem]"> {item.fields.title}</span>
    </Link>
  ) : null
}

export default MenuLink
