import Icons from '../Icons'
import React from 'react'

type IconName = keyof typeof Icons
type IconProps = {
  name: IconName | string
  baseClass?: string
  className?: string
  onClick?(): void
}

export const Icon = ({ name, className, baseClass, ...rest }: IconProps) => {
  // Prevent undefined (or in-development icons) crashing build
  const icon = Icons?.[name]
  if (!icon) {
    return undefined
  }
  return (
    <div className={`${className || ''} ${baseClass || ''}`} {...rest}>
      {React.createElement(icon, { className })}
    </div>
  )
}
