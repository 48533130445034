import { TypeButton, TypeMarketoFormButtonBlock, TypePerson } from '../../types/contentful'
import { CardShape, CardSize } from '../../types/content'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import React from 'react'
import Card from './Card'
import CardV2 from './CardV2'

type PersonCardProps = {
  person: TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
  shape?: CardShape
  size?: CardSize
  type?: 'Default' | 'Description' | 'Promotion'
  cornersStyle?: 'Rounded' | 'Square'
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

// Render Person
export const PersonCard = ({
  person,
  size,
  shape,
  type,
  cornersStyle,
  button,
}: PersonCardProps) => {
  const { firstName, lastName, title, slug, tags, hero, description, content } = person.fields
  if (type === 'Promotion') {
    return (
      <CardV2
        title={`${firstName} ${lastName}`}
        subtitle={title ? `${title}` : ''}
        slug={`/people/${slug}`}
        size={size}
        thumbnailWithFocalPoint={getImageWithFocalPoint(hero)}
        cardType="Person"
        tags={tags}
        shape={shape}
        type={type}
        cornersStyle={cornersStyle}
        button={button}
        description={description}
        content={content}
        {...person}
      />
    )
  } else {
    return (
      <Card
        title={`${firstName} ${lastName}`}
        subtitle={title ? `${title}` : ''}
        slug={`/people/${slug}`}
        size={size}
        thumbnailWithFocalPoint={getImageWithFocalPoint(hero)}
        cardType="Person"
        tags={tags}
        shape={shape}
        type={type}
        cornersStyle={cornersStyle}
        button={button}
        description={description}
        hideTags
        {...person}
      />
    )
  }
}
