import {
  TypeButton,
  TypeImageWithFocalPoint,
  TypePerson,
  TypeTag,
  TypeMarketoFormButtonBlock,
} from '../../types/contentful'
import Link from 'next/link'
import dayjs from 'dayjs'
import Image from 'next/legacy/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import classnames from 'classnames'
import React from 'react'
import { Icon } from '../Icon/Icon'
import { getShapeClass } from '../Shapes/Shapes'
import { CardShape, CardSize } from '../../types/content'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import CardTag from './CardTag'
import { renderCTAButtons } from '../CTAButtons/CTAButtons'
import { isContentfulType } from '../../lib/contentful'
import { CourseType } from '../../types/graphql'

type CardPropTypes = {
  title: string
  subtitle?: string
  description?: string
  className?: string
  slug?: string
  author?: TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
  readTime?: number
  createdAt?: string
  size?: CardSize
  thumbnailWithFocalPoint?: TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
  cardType?: 'Event' | 'Article' | 'Course' | 'Person'
  tags?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>[]
  hideTags?: boolean
  priceFree?: boolean
  price?: string
  currency?: string
  timeframe?: string
  credits?: number
  time?: string
  location?: string
  shape?: CardShape
  thumbnailUrl?: string
  type?: 'Default' | 'Description' | 'Promotion'
  cornersStyle?: 'Rounded' | 'Square'
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  isComingSoon?: boolean
  backgroundColor?: string
  textColor?: string
  durationDescription?: string
  timeframeIcon?: string
  durationDescriptionIcon?: string
  onCardClick?: () => void
  courseType?: CourseType
  isCaseStudy?: boolean
  eoiOnly?: boolean
}

export const contentfulType = {
  Default: 'Default',
  Description: 'Description',
}

const Card = ({
  title,
  subtitle,
  description,
  slug,
  onCardClick,
  author,
  createdAt,
  size = 'Small Square',
  thumbnailWithFocalPoint,
  thumbnailUrl,
  readTime,
  cardType,
  tags,
  hideTags,
  priceFree,
  price,
  currency,
  timeframe,
  credits,
  time,
  location,
  shape,
  type = 'Default',
  cornersStyle,
  button,
  isComingSoon = false,
  durationDescription,
  timeframeIcon = 'Calendar',
  durationDescriptionIcon = 'Clock',
  courseType,
  isCaseStudy,
  eoiOnly,
}: CardPropTypes) => {
  const createdAtDate = createdAt ? dayjs(createdAt).format('MMM DD, YYYY') : null
  const thumbnailFocalRatio = getFocalRatio(thumbnailWithFocalPoint?.fields)

  const typeData = {
    Event: {
      label: 'Event',
      slug: '/browse/events',
    },
    Course: {
      label: 'Learn',
      slug: '/browse/courses',
    },
    Article: {
      label: isCaseStudy ? 'Case study' : 'Reading',
      slug: `/browse/stories`,
    },
    Person: {
      label: 'People',
      slug: '/browse/people',
    },
  }

  const cardStyle = classnames(
    size === 'Medium Wide' || size === 'Large Square' ? 'sm:col-span-2' : ''
  )

  const imageStyle = classnames(
    'relative block w-full mb-6',
    getShapeClass(size, shape),
    size === 'Medium Tall' || size === 'Large Square' ? 'h-80 sm:h-[40rem]' : 'h-80'
  )

  const tagStyle = classnames(cornersStyle === 'Square' ? '' : 'rounded-md ')
  const image = getImageWithFocalPoint(author?.fields?.hero)
  const authorPhotoFocalRatio = getFocalRatio(image?.fields)

  if (
    isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(button, 'button') &&
    !button.fields.url
  ) {
    const new_button = button
    new_button.fields.url = slug
  }
  return (
    <div>
      <Link href={slug} className={cardStyle} onClick={onCardClick}>
        {/* Start Thumbnail */}
        {(thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url || thumbnailUrl) && (
          <div className={imageStyle}>
            {isComingSoon && (
              <div
                className={`absolute z-10 right-3 top-3 bg-secondary-600 px-3 py-1 bg-[#E0E0E0] ${
                  cornersStyle === 'Square' ? '' : 'rounded-lg'
                }`}
              >
                coming soon
              </div>
            )}
            <Image
              className={`duration-300 hover:scale-105 transition`}
              src={
                thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url
                  ? `https:${thumbnailWithFocalPoint?.fields?.image?.fields?.file?.url}`
                  : thumbnailUrl
              }
              alt={
                thumbnailWithFocalPoint?.fields?.image?.fields?.description ||
                thumbnailWithFocalPoint?.fields?.image?.fields?.title ||
                title
              }
              layout="fill"
              objectFit="cover"
              objectPosition={`${thumbnailFocalRatio.x}% ${thumbnailFocalRatio.y}%`}
              sizes={size === 'Small Square' ? '40rem' : '80rem'}
            />
          </div>
        )}
        {/* End Thumbnail */}
        {/* Title for Description */}
        {type === contentfulType.Description && (
          <div className="flex flex-col mb-4">
            <div className="font-primary text-2xl font-bold">{title}</div>

            {!!subtitle && <span className="font-primary">{subtitle}</span>}
          </div>
        )}
        {/* Start Tags */}
        {!hideTags && (
          <div className="flex justify-items-start mb-4 flex-wrap">
            {type !== contentfulType.Description && (
              <CardTag
                isPrimeTag
                label={
                  (cardType === 'Article' && tags?.[0]?.fields?.label) || typeData[cardType].label
                }
                tagStyle={tagStyle}
              />
            )}
            {(cardType === 'Article' ? tags?.slice(1) : tags)?.map((tag, index) => {
              return (
                <CardTag
                  key={index}
                  label={tag?.fields?.label}
                  type={type}
                  tagStyle={tagStyle}
                  backgroundColor={tag?.fields?.backgroundColor}
                  textColor={tag?.fields?.textColor}
                />
              )
            })}
          </div>
        )}
        {/* End Tags */}
        {type === contentfulType.Default && (
          <div className="flex flex-col mb-4">
            {/* Title for Default */}

            <div className="font-primary text-2xl">{title}</div>
            {/* Subtitle */}
            {!!subtitle && (
              <span className="font-secondary text-base font-extralight">{subtitle}</span>
            )}
          </div>
        )}
        {/* Description */}
        {(isCaseStudy || type === contentfulType.Description) && description && (
          <p
            className={`text-secondary-ui-700 ${isCaseStudy ? 'text-base' : 'text-sm font-medium'}`}
          >
            {description}
          </p>
        )}
        {/* Start Course Info */}
        {(timeframe || credits || time) && (
          <div className="flex mb-5">
            {timeframe && (
              <div className={`flex mr-4 flex-row items-center leading-5`}>
                <Icon className="w-5 h-5 mr-2 md:mr-1" name={timeframeIcon} />
                {timeframe}
              </div>
            )}
            {!credits && !!durationDescription && (
              <div className="flex mr-4 flex-row items-center leading-5">
                <Icon className="w-5 h-5 mr-2 md:mr-1" name={durationDescriptionIcon} />
                {durationDescription}
              </div>
            )}
            {credits && (
              <div className="flex mr-4 flex-row items-center leading-5">
                <Icon className="w-5 h-5 mr-2 md:mr-1" name="GraduateHatOutline" />
                {credits} Credits
              </div>
            )}
            {time && (
              <div className="flex mr-4 flex-row items-center leading-5">
                <Icon className="w-5 h-5 mr-2 md:mr-1" name="Clock" /> {time}
              </div>
            )}
          </div>
        )}
        {/* End Course Info */}
        {/* Start Author, Date and Read Time */}
        {!isCaseStudy && (createdAtDate || readTime || author) && (
          <div className="flex pb-4">
            {image?.fields?.image?.fields?.file?.url && (
              <div className="relative w-10 h-10 rounded-full overflow-hidden mr-1.5">
                <Image
                  src={`https:${image?.fields?.image?.fields?.file.url}`}
                  alt={
                    image?.fields?.image?.fields.description || image?.fields?.image?.fields.title
                  }
                  layout="fill"
                  objectFit="cover"
                  objectPosition={`${authorPhotoFocalRatio.x}% ${authorPhotoFocalRatio.y}%`}
                  sizes="2.5rem"
                />
              </div>
            )}

            <div>
              {(author?.fields?.firstName || author?.fields?.lastName) && (
                <div className="text-secondary-ui-700 font-medium text-sm">{`${author?.fields?.firstName} ${author?.fields?.lastName}`}</div>
              )}
              <div className="text-secondary-ui-500 font-medium text-sm">
                {createdAtDate && <span>{createdAtDate}</span>}
                {createdAtDate && readTime > 0 && <span> &middot; </span>}
                {readTime > 0 && <span>{`${readTime} min read`}</span>}
              </div>
            </div>
          </div>
        )}
        {/* End Author, Date and Read Time */}
        {isCaseStudy && (
          <div className="flex flex-row items-center hover:underline">
            <p className="m-0 mr-3 text-accent-4-700">Learn more</p>
            <Icon name="ArrowRight" className="w-4 h-4 text-accent-4-700" />
          </div>
        )}
        {type === contentfulType.Default && (
          <>
            {eoiOnly ? (
              <div className="font-medium my-5">
                <span className="text-xl mr-1">Price on enquiry</span>
              </div>
            ) : (
              <>
                {/** Free prices */}
                {priceFree && (
                  <div className="font-medium my-5">
                    <span className="text-xl mr-1">Free</span>
                  </div>
                )}

                {/* Start Price */}
                {price && !priceFree && (
                  <div className="font-medium my-5">
                    {courseType === CourseType.Masters && currency === 'NZD' && !priceFree && (
                      <span className="text-sm font-thin mr-2">UP TO</span>
                    )}
                    <span className="text-xl mr-1">{price}</span>
                    <span className="text-sm">{currency}</span>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {location && (
          <div className="flex items-center mr-4">
            <Icon className="w-5 h-5 mr-1" name="Location" /> {location}
          </div>
        )}
      </Link>
      {type === contentfulType.Description &&
        button &&
        renderCTAButtons({ buttons: [button], slug })}
    </div>
  )
}

export default Card
