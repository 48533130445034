import React from 'react'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import { Loading } from '../Loading/Loading'
import * as Sentry from '@sentry/nextjs'

interface HubspotEmbeddedFormProps {
  portalId: string
  formId: string
  title: string
  displayTitle?: boolean
  isModal?: boolean
}

export const HubspotEmbeddedForm = ({
  portalId,
  formId,
  title,
  displayTitle,
  isModal,
}: HubspotEmbeddedFormProps) => {
  const { loaded, error } = useHubspotForm({
    portalId: portalId,
    formId: formId,
    target: `#form-${formId}`,
  })

  if (error) {
    Sentry.captureException(error)
  }

  return (
    <div className="w-full gap-6 flex flex-col items-center justify-center">
      {displayTitle && title && (
        <h2 className="font-primary font-bold mb-4 mt-0 text-2xl text-center">{title}</h2>
      )}
      <div className={`w-full ${!isModal ? 'max-w-[62rem]' : ''}`}>
        {formId && loaded && <div id={`form-${formId}`}></div>}
        {!loaded && <Loading />}
        {!formId && (
          <div className="w-full h-full flex items-center justify-center">
            formId was not provided to the form
          </div>
        )}
      </div>
    </div>
  )
}
