import { TypeTag } from '../../types/contentful'
import { useState } from 'react'
import { Icon } from '../Icon/Icon'

type CategoryDropdownProps = {
  tags?: Array<TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>>
  selectedTag?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>
  onSelection: (tag?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>) => Promise<void>
}
const CategoryDropdown = ({ tags, selectedTag, onSelection }: CategoryDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const chevronClasses = `ml-1 w-7 inline transition-all ${!isOpen ? 'rotate-180' : ''}`
  const dropdownContainerClass = `absolute right-0 bg-white drop-shadow mt-2 w-64 z-10 transition-all h-0 overflow-hidden ${
    isOpen ? '!h-fit' : ''
  }`
  return (
    <div className={'inline sm:relative cursor-pointer'}>
      <span className={'ml-2 underline'} onClick={() => setIsOpen(!isOpen)}>
        {selectedTag ? selectedTag.fields.label : 'choose option'}
        <Icon name={'ChevronUpIcon'} className={chevronClasses} />
      </span>
      <div className={dropdownContainerClass}>
        <ul className={'list-none font-light font-secondary p-0 m-0 py-5 cursor-pointer'}>
          <li
            className={'text-base text-left border-b-[1px] border-secondary-ui-100 px-5 py-2'}
            onClick={() => {
              setIsOpen(!isOpen)
              onSelection(null)
            }}
          >
            Anything
          </li>

          {tags
            ?.filter(tag => tag?.fields)
            .map((t: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>) => (
              <li
                key={`${t.fields.slug}-home-filter`}
                className={'text-base text-left px-5 py-2'}
                onClick={() => {
                  setIsOpen(!isOpen)
                  onSelection(t)
                }}
              >
                {t.fields.label}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default CategoryDropdown
