import React from 'react'
import { TypeListItem } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'

type VerticalListBlockProps = {
  listItem: TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const VerticalListBlock = ({ listItem }: VerticalListBlockProps) => {
  const iconWrapperClass = `inline-flex items-center justify-center rounded-full p-4 shadow-lg`

  return (
    <div>
      {listItem.fields.icon && (
        <div className={iconWrapperClass} style={{ backgroundColor: listItem.fields.colour }}>
          <Icon className="h-10 w-10" aria-hidden="true" name={listItem.fields.icon} />
        </div>
      )}

      <h3 className="mt-8 text-lg font-medium tracking-tight mb-5">{listItem.fields.title}</h3>
      <div>{renderDocumentToReactComponents(listItem.fields.content)}</div>
    </div>
  )
}

export default VerticalListBlock
