import { TypeCourse, TypeEvent } from '../types/contentful'
import { isContentfulType } from '../lib/contentful'
import { Entry } from 'contentful'
import { Course, SocialEvent } from '../types/graphql'
import { SSR_LOCALE } from '../config/locale'
import { nextIntake } from '../lib/courses'
import { ICourseExtended, IEventExtended } from '../types/types'

/**
 * Merge contentful course with lms
 *
 * @param contentfulCourse
 * @param lmsCourse
 */
export const mergeCourseFields = (
  contentfulCourse: TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>,
  lmsCourse?: Course
): ICourseExtended => {
  if (!lmsCourse) {
    return contentfulCourse
  }
  return {
    ...contentfulCourse,
    fields: {
      ...contentfulCourse.fields,
      ...lmsCourse,
      title: contentfulCourse.fields.title || null,
      description: contentfulCourse.fields.description || lmsCourse.overview || null,
    },
  }
}

/**
 * Merge contentful event with lms
 *
 * @param contentfulEvent
 * @param lmsEvent
 */
export const mergeEventFields = (
  contentfulEvent: TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>,
  lmsEvent?: SocialEvent
): IEventExtended => {
  if (!lmsEvent) {
    return contentfulEvent
  }
  return {
    ...contentfulEvent,
    fields: {
      ...contentfulEvent.fields,
      ...lmsEvent,
      title: contentfulEvent.fields.title || null,
    },
  }
}

/**
 * Extend a single item with extra fields from graphql
 * @param itemData
 * @param courses
 * @param events
 */
export const mergeItemFields = <T extends Entry<any>>(
  itemData: T,
  courses: Array<Course>,
  events: Array<SocialEvent>
): IEventExtended | ICourseExtended | T => {
  if (isContentfulType<TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>>(itemData, 'course')) {
    const course =
      typeof itemData.fields.slug === 'string'
        ? courses?.find(item => item?.slug === itemData.fields.slug)
        : courses?.find(item => item?.slug === itemData.fields.slug[SSR_LOCALE])
    return mergeCourseFields(itemData, course)
  }
  if (isContentfulType<TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>>(itemData, 'event')) {
    const event =
      typeof itemData.fields.slug === 'string'
        ? events?.find(item => item?.slug === itemData.fields.slug)
        : events?.find(item => item?.slug === itemData.fields.slug[SSR_LOCALE])
    return mergeEventFields(itemData, event)
  }

  // Nothing to merge, just return basic block
  return itemData
}

/**
 * Merge a large list of items into extended fields
 *
 * @param items
 * @param courses
 * @param events
 */
export const mergeManyItemFields = <T extends Entry<any>>(
  items: Array<T>,
  courses: Array<Course>,
  events: Array<SocialEvent>
): Array<T | IEventExtended | ICourseExtended> => {
  return items.map(item => mergeItemFields(item, courses, events))
}

/**
 * Get date this item should be sorted by
 *
 * @param item
 * @param onlyLastUpdated
 */
const getItemDate = (item: Entry<any>, onlyLastUpdated: boolean): Date => {
  // For home page we only want to sort on this field
  if (onlyLastUpdated) {
    return new Date(item.sys.updatedAt)
  }

  // Sort evens by start time
  if (!onlyLastUpdated && isContentfulType<IEventExtended>(item, 'event')) {
    const firstInstance = item.fields.instances?.[0]
    if (firstInstance && firstInstance.startsAt) {
      return new Date(firstInstance.startsAt)
    }
  }

  // Sort courses by start time
  if (!onlyLastUpdated && isContentfulType<ICourseExtended>(item, 'course')) {
    // Rolling start dates sorted first
    if (item.fields.startType === 'Rolling') {
      return new Date(1970, 1, 1)
    }
    const latestIntake = nextIntake(item)
    if (latestIntake && latestIntake.dateStart) {
      return new Date(latestIntake.dateStart)
    }
  }

  // Fail over to sort by updated date
  return new Date(item.sys.updatedAt)
}

/**
 * Sort all cards
 * @param items
 * @param onlyLastUpdated Sort by lastUpdated field only
 * @param direction
 */
export const sortCards = <T extends Entry<any>>(
  items: Array<T>,
  onlyLastUpdated: boolean,
  direction: 'asc' | 'desc'
): Array<T> => {
  const num = direction === 'asc' ? -1 : 1
  return items.sort((left, right) => {
    const leftDate = getItemDate(left, onlyLastUpdated)
    const rightDate = getItemDate(right, onlyLastUpdated)
    return leftDate < rightDate ? num : -num
  })
}
