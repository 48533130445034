import { TypeNavigationItem } from '../../types/contentful'
import React from 'react'
import MenuLink from '../Menu/MenuLink'

type MenuItemProps = {
  item: TypeNavigationItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const FooterItem = ({ item }: MenuItemProps) => {
  return (
    <li className="dark:text-white font-inter leading-8 text-base font-light text-center md:text-left">
      <MenuLink item={item} type="footer" />
    </li>
  )
}

export default FooterItem
