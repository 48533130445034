import { Course, CourseIntake, CourseType } from '../types/graphql'
import dayjs from 'dayjs'
import slugify from 'slugify'
import { ICourseExtended } from '../types/types'

const getUpcomingIntakes = (course: ICourseExtended): CourseIntake[] => {
  const intakes = course.fields.publicIntakes || []
  if (course.fields.startType === 'Rolling') {
    return intakes
  }

  const now = dayjs()
  return intakes.filter(e => {
    if (!e.dateStart) {
      return false
    }

    const startDate = dayjs(e.dateStart)
    return startDate.isAfter(now)
  })
}

// 1. At the moment it sorts only by "earliest first".
// 2. Intakes without dates are placed at the bottom of the list.
//
// A descending sort option can be added in the future if a use case arises.
// @see sortCoursesByDuration
const sortIntakesByStartDate = (intakes: CourseIntake[]): CourseIntake[] => {
  return intakes.sort((a, b) => {
    if (!a.dateStart && !b.dateStart) {
      return 0
    } else if (a.dateStart && !b.dateStart) {
      return -1
    } else if (!a.dateStart && b.dateStart) {
      return 1
    }

    const startDateA = dayjs(a.dateStart as string)
    const startDateB = dayjs(b.dateStart as string)
    return startDateA.isSame(startDateB) ? 0 : startDateA.isAfter(startDateB) ? 1 : -1
  })
}

export const nextIntake = (course: ICourseExtended): CourseIntake | null => {
  const upcomingIntakes = getUpcomingIntakes(course)
  if (upcomingIntakes.length === 0) {
    return null
  }

  const sortedIntakes = sortIntakesByStartDate(upcomingIntakes)

  return sortedIntakes[0]
}

/**
 * Get slug for course type
 * You can pass in either a course, or the type directly
 *
 * @param course
 */
export const getCourseTypeSlug = (course: Course | CourseType | undefined): string => {
  // Default to micro-credential
  if (!course) {
    return 'micro-credential'
  }
  const type = typeof course === 'string' ? course : course.type
  switch (type) {
    case CourseType.Microcredential:
      return 'micro-credential'
    case CourseType.FreeCourse:
      return 'sampler'
    case CourseType.ShortCourse:
      return 'advancer'
    case CourseType.Masters:
      return 'master'
    case CourseType.PostgradCertificate:
      return 'postgrad-cert'
    default:
      // Note: Should not actually ever happen, but just in case
      return slugify(type)
  }
}
