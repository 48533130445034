import React, { useMemo } from 'react'
import { styles } from '../../config/contentful'
import { fontSize, HeadingProps } from '../Typography/Typography'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'

type TitleBlockProps = {
  title: string
  preTitle: string
  children?: React.ReactNode
  colour?: string
  hasImage?: boolean
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  align?: string
  isHero?: boolean
  size?: HeadingProps['size']
  description?: string
  textColor?: string
  customClassname?: string
  contenfulId?: string
  descriptionWidth?: 'Narrow' | 'Wide'
  preTitleAlignment?: 'Left' | 'Center' | 'Right'
  titleAlignment?: 'Left' | 'Center' | 'Right'
  descriptionAlignment?: 'Left' | 'Center' | 'Right'
  isQuote?: boolean
}

const getOverideAlignmentClass = (alignment: string) => {
  switch (alignment) {
    case 'Left':
      return '!text-left'
    case 'Center':
      return '!text-center'
    case 'Right':
      return '!text-right'
    default:
      return ''
  }
}

const TitleBlock = ({
  title,
  preTitle,
  children,
  colour = 'transparent',
  customClassname,
  hasImage = false,
  headingType = 'h1',
  align = 'center',
  isHero = false,
  size = '6xl',
  description,
  textColor,
  contenfulId,
  descriptionWidth,
  preTitleAlignment,
  titleAlignment,
  descriptionAlignment,
  isQuote,
}: TitleBlockProps) => {
  const HeadingTag = headingType

  const headingStyles = {
    h1: styles.renderNode.heading_1,
    h2: styles.renderNode.heading_2,
    h3: styles.renderNode.heading_3,
    h4: styles.renderNode.heading_4,
    h5: styles.renderNode.heading_5,
    h6: styles.renderNode.heading_6,
  }

  const headingStyle = classNames(
    headingStyles[headingType] || styles.renderNode.heading_1,
    fontSize[size]
  )

  const preTitleAlignmentClass = useMemo(
    () => getOverideAlignmentClass(preTitleAlignment),
    [preTitleAlignment]
  )
  const titleAlignmentClass = useMemo(
    () => getOverideAlignmentClass(titleAlignment),
    [titleAlignment]
  )
  const descriptionAlignmentClass = useMemo(
    () => getOverideAlignmentClass(descriptionAlignment),
    [descriptionAlignment]
  )

  const defaultTextPosition = useMemo(() => {
    switch (align) {
      case 'left':
        return 'text-left'
      case 'center':
        return 'text-center'
      case 'right':
        return 'text-right'
      default:
        return ''
    }
  }, [align])

  const descriptionClass = descriptionWidth !== 'Wide' ? 'w-[90%] lg:w-1/2' : 'w-[90%]'
  return (
    <div
      className={classNames('w-full', isHero && 'pt-28 md:pt-36 bg-title-block')}
      style={{ backgroundColor: colour, color: textColor }}
    >
      <div className={classNames(hasImage ? 'bg-title-block' : isHero ? 'pb-12' : 'pb-4')}>
        {preTitle && (
          <p
            className={classNames(
              'uppercase text-secondary-ui-600 dark:text-primary-ui-white font-inter mb-4',
              defaultTextPosition,
              customClassname,
              preTitleAlignmentClass
            )}
          >
            {preTitle}
          </p>
        )}
        <HeadingTag
          className={classNames(
            headingStyle,
            defaultTextPosition,
            customClassname,
            titleAlignmentClass
          )}
          id={`${contenfulId}-title`}
        >
          {title}
        </HeadingTag>
        {!!description && (
          <div
            className={classNames('flex mt-10', isQuote ? 'items-start' : 'items-center')}
            style={!isQuote ? { justifyContent: align } : {}}
            id={`${contenfulId}-description`}
          >
            <ReactMarkdown
              className={classNames(
                'text-xl',
                'markdown',
                descriptionClass,
                defaultTextPosition,
                customClassname,
                descriptionAlignmentClass
              )}
            >
              {description}
            </ReactMarkdown>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default TitleBlock
