import { TypeNavigationMenu } from '../../types/contentful'
import React, { useMemo } from 'react'
import MenuItem from './MenuItem'
import Link from 'next/link'

/**
 * Group of items
 * @param menu
 * @param handleClose
 * @constructor
 */

type MenuGroupProp = {
  menu: TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>
  handleClose?: () => void
  activeSlug?: string
  hoverColor: string
  type?: 'footer' | 'menu' | 'navbar'
}
const MenuGroup = ({ menu, handleClose, activeSlug, hoverColor, type }: MenuGroupProp) => {
  const textStyle = useMemo(() => {
    switch (menu?.fields?.title) {
      case 'Top navigation':
        return 'flex flex-row'
      default:
        return 'text-inherit'
    }
  }, [menu?.fields?.title])

  const ulStyle =
    menu?.fields?.title === 'Top navigation'
      ? 'p0 m-0 list-none flex flex-row'
      : 'p0 m-0 mb-3 list-none'

  const menuItemStyle = 'text-sm text-inherit'

  const LinkWrapper = ({ href, children }: { href: string; children: React.ReactNode }) => {
    return href ? (
      <Link href={href} legacyBehavior>
        {children}
      </Link>
    ) : (
      <>{children}</>
    )
  }

  return (
    <div className={`border-secondary-ui-200 border-b last:border-b-0 ${textStyle}`}>
      {!!menu?.fields?.titleLink && (
        <LinkWrapper href={menu?.fields?.link || ''}>
          <a
            className={`px-4 py-3 pt-6 block text-xs uppercase menu-item`}
            {...(menu?.fields?.link ? { onClick: handleClose } : {})}
          >
            {menu?.fields?.titleLink}
          </a>
        </LinkWrapper>
      )}
      <ul className={ulStyle}>
        {menu.fields.items.map(item => (
          <MenuItem
            key={item.sys.id}
            item={item}
            className={menuItemStyle}
            handleClose={handleClose}
            hoverColor={hoverColor}
            activeSlug={activeSlug}
            type={type}
          />
        ))}
      </ul>
    </div>
  )
}

export default MenuGroup
