import { GraphqlResources } from '../../types/content'
import { createContext, useContext } from 'react'

const ContentfulResourceContext = createContext<GraphqlResources>({
  events: [],
  courses: [],
  useSection: false,
})

export const useContentfulResources = () => {
  return useContext(ContentfulResourceContext)
}

export default ContentfulResourceContext
