import React from 'react'
import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'

const Container = ({
  children,
  className,
  style,
  id,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { useSection } = useContentfulResources()
  return useSection ? (
    <section className={className} style={style} id={id} {...rest}>
      {children}
    </section>
  ) : (
    <div className={className} style={style} id={id} {...rest}>
      {children}
    </div>
  )
}

export default Container
