import { TypeNavigationMenu } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import React from 'react'

const FooterSocialIcons = ({
  menu,
}: {
  menu: TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>
}) => {
  return (
    <div className="flex">
      <ul className={'p-0 list-none flex flex-row gap-4'}>
        {menu?.fields?.items?.map(item => (
          <li key={item.sys.id} className="flex">
            <a href={item.fields.link} target="_blank" rel="external noopener noreferrer">
              <Icon name={item.fields.icon} className="w-7 h-6" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FooterSocialIcons
