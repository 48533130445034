import { useState, useEffect, useRef } from 'react'

const useTextClamp = (MAX_HEIGHT: number) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [shouldClamp, setShouldClamp] = useState(false)
  const clampTextRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    const textElement = clampTextRef.current

    if (textElement) {
      const lineHeight = parseInt(getComputedStyle(textElement).lineHeight, 10)
      const textHeight = textElement.clientHeight
      setShouldClamp(textHeight > lineHeight * MAX_HEIGHT)
    }
  }, [MAX_HEIGHT])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return { isExpanded, shouldClamp, clampTextRef, toggleExpand }
}

export default useTextClamp
