import React from 'react'
import { TypeTextBlock } from '../../types/contentful'
import TitleBlock from '../TitleBlock/TitleBlock'

type TextBlockProps = {
  entry: TypeTextBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const TextBlock = ({ entry }: TextBlockProps) => {
  return (
    <TitleBlock
      title={entry?.fields?.title}
      preTitle={entry?.fields?.preTitle}
      headingType={'h2'}
      size={entry?.fields?.titleSize || '4xl'}
      preTitleAlignment={entry?.fields?.preTitleAlignment}
      descriptionAlignment={entry?.fields?.contentAlignment}
      titleAlignment={entry?.fields?.titleAlignment}
      description={entry?.fields?.content}
      isQuote={entry?.fields?.isQuote}
      descriptionWidth="Wide"
      contenfulId={entry.sys.id}
    />
  )
}

export default TextBlock
