import { TypeAccordion } from '../../types/contentful'
import AccordionBlockItem from './AccordionBlockItem'
import TitleBlock from '../TitleBlock/TitleBlock'
import { fontSize, H3 } from '../Typography/Typography'
import isDarkColor from '../../helpers/isDarkColor'
import { CSSProperties } from 'react'

type AccordionBlockProps = {
  block: TypeAccordion<'WITHOUT_UNRESOLVABLE_LINKS', string>
  position?: 'top' | 'middle' | 'bottom'
}

const AccordionBlock = ({ block, position }: AccordionBlockProps) => {
  const { preTitle, title, showTitle, heading, items, backgroundColour, color } = block.fields
  const top = position === 'top',
    middle = position === 'middle',
    bottom = position === 'bottom'

  const accordionColorCSSVars = {
    '--accordion-color': color,
  } as CSSProperties

  return (
    <div
      className={`full-width ${
        backgroundColour && isDarkColor(backgroundColour) ? 'text-white dark' : ''
      }`}
      style={{ backgroundColor: backgroundColour }}
    >
      <div
        className={`container mx-auto max-w-4xl ${
          top
            ? 'pt-3 md:pt-6 lg:pt-12'
            : middle
            ? ''
            : bottom
            ? 'pb-3 md:pb-6 lg:pb-12'
            : 'py-3 md:py-6 lg:py-12'
        }`}
      >
        {showTitle === true && (
          <div className={`mb-5`}>
            <TitleBlock title={title} preTitle={preTitle} headingType={`h2`} size={`5xl`} />
          </div>
        )}
        <div className={'p-8 lg:bg-[--accordion-color]'} style={accordionColorCSSVars}>
          {heading && (
            <H3 className={`my-0 font-primary font-bold ${fontSize['2xl']}`}>{heading}</H3>
          )}
          <div className={`mt-6`}>
            {items.map((item, index) => (
              <AccordionBlockItem key={item.sys.id} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionBlock
