// Render Event
import { date } from '../../lib/date'
import { useLocation } from '../../context/Location/LocationProvider'
import React, { useEffect, useState } from 'react'
import { formatPrice, getCurrency } from '../../lib/locale'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import Card from './Card'
import { CardShape, CardSize } from '../../types/content'
import { IEventExtended, Price } from '../../types/types'
import { TypeButton, TypeMarketoFormButtonBlock } from '../../types/contentful'

type EventCardProps = {
  event: IEventExtended
  shape?: CardShape
  size?: CardSize
  type?: 'Default' | 'Description' | 'Promotion'
  cornersStyle?: 'Rounded' | 'Square'
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  onCardClick?: () => void
}

export const EventCard = ({
  event,
  onCardClick,
  size,
  shape,
  cornersStyle,
  button,
}: EventCardProps) => {
  const {
    title,
    slug,
    tags,
    priceNZ,
    priceAU,
    priceUS,
    thumbnail,
    hero,
    instances,
    location,
    thumbnailUrl,
  } = event.fields

  const [hydratedTimeframe, setHydratedTimeframe] = useState('')
  const [hydratedTime, setHydratedTime] = useState('')

  const lastInstanceIndex = instances?.length - 1

  useEffect(() => {
    const timeframe =
      instances?.length > 0 && instances?.[lastInstanceIndex]?.startsAt
        ? date(instances[lastInstanceIndex].startsAt).format('DD/MM/YYYY')
        : null
    const time = instances?.[lastInstanceIndex]?.startsAt
      ? date(instances?.[lastInstanceIndex]?.startsAt).format('h:mmA')
      : null

    setHydratedTimeframe(timeframe)
    setHydratedTime(time)
  }, [lastInstanceIndex, instances])

  const { country, region } = useLocation()

  const currency = getCurrency(country, region)
  const price = formatPrice({
    priceNZ,
    priceAU,
    priceUS,
    country,
    region,
  })

  return (
    <Card
      title={title}
      slug={`/events/${slug}`}
      onCardClick={onCardClick}
      size={size}
      thumbnailWithFocalPoint={thumbnail || getImageWithFocalPoint(hero)}
      thumbnailUrl={thumbnailUrl}
      cardType="Event"
      tags={tags}
      price={price}
      priceFree={!price || price === Price.FREE}
      currency={currency}
      timeframe={hydratedTimeframe}
      time={hydratedTime}
      location={location}
      shape={shape}
      cornersStyle={cornersStyle}
      button={button}
      {...event}
    />
  )
}
