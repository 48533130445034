import getFocalRatio from '../../helpers/getFocalRatio'
import Quote from '../Icons/Quote'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { QuoteContentBlockProps } from './QuoteContentBlock'
import Container from '../Container/Container'
import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'

const QuoteContentBlockDefault = ({ entry }: QuoteContentBlockProps) => {
  const { copy, person, backgroundColor, textColor, redirect, hideAuthorImage } = entry.fields
  const photo = getImageWithFocalPoint(person?.fields?.hero)
  const focalRatio = getFocalRatio(photo?.fields)
  const fullName = `${person?.fields?.firstName}${
    person?.fields?.lastName ? ' ' + person?.fields?.lastName : ''
  }`
  const { useSection } = useContentfulResources()

  const LinkWrapper = ({ children, href }) => {
    return redirect ? (
      <Link href={href} legacyBehavior>
        {children}
      </Link>
    ) : (
      <>{children}</>
    )
  }

  return (
    <Container className={`${useSection ? 'full-width ' : ''}p-3`}>
      <div
        className={'container mx-auto relative mt-12 bg-accent-4-50 p-8 rounded-lg'}
        style={{ color: textColor ?? '', backgroundColor: backgroundColor ?? '' }}
        id={entry?.sys?.id}
      >
        <Quote color="#D2DCF7" className="mb-2" />
        <p className={'text-lg font-semibold mb-0 !text-left'}>{copy}</p>

        {person?.fields && (
          <LinkWrapper href={`/people/${person.fields.slug}`}>
            <div
              className={`h-20 sm:h-16 md:h-20 lg:h-16 relative flex items-center ${
                redirect ? 'cursor-pointer' : ''
              }`}
            >
              {photo?.fields?.image?.fields?.file?.url && !hideAuthorImage && (
                <div
                  className={`relative w-16 h-16 sm:w-20 sm:h-20 rounded-full overflow-clip border-primary-ui-white border-2 shrink-0`}
                >
                  <Image
                    src={`https:${photo.fields.image.fields.file.url}`}
                    alt={photo.fields.title}
                    layout="fill"
                    objectFit="cover"
                    objectPosition={`${focalRatio?.x || 50}% ${focalRatio?.y || 50}%`}
                    // For viewports greater than 512px set the image width to 5.25rem (84px) else 3.25rem (52px)
                    sizes="(max-width: 512px) 3.25rem, 5.25rem"
                  />
                </div>
              )}
              <div className={'capitalize'}>
                <span>{fullName}</span>
                {person?.fields?.title && <span>, {person?.fields?.title}</span>}
              </div>
            </div>
          </LinkWrapper>
        )}
      </div>
    </Container>
  )
}

export default QuoteContentBlockDefault
