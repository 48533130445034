import { ProductList } from './model/product-list.model'
import {
  checkoutStart,
  setDisplayedProducts,
  setSelectedProduct,
  setViewedProduct,
} from './product-data-layer'
import { createCourseProduct, createCourseProducts } from './model/course.adapter'
import { ICourseExtended } from '../../types/types'
import { getCurrency } from '../../lib/locale'

export const setDisplayedCourses = (
  list: ProductList,
  courses: ICourseExtended[],
  country: string,
  region?: string
) =>
  setDisplayedProducts(
    list,
    getCurrency(country, region),
    createCourseProducts(list, courses, country, region)
  )

export const setSelectedCourse = (
  list: ProductList,
  course: ICourseExtended,
  country: string,
  region?: string,
  index?: number
) =>
  setSelectedProduct(
    list,
    getCurrency(country, region),
    createCourseProduct(list, course, country, region, index)
  )

export const setViewedCourse = (
  list: ProductList,
  course: ICourseExtended,
  country: string,
  region?: string
) =>
  setViewedProduct(
    list,
    getCurrency(country, region),
    createCourseProduct(list, course, country, region)
  )

export const startCourseCheckout = (
  list: ProductList,
  course: ICourseExtended,
  country: string,
  region?: string,
  locationType?: string
) =>
  checkoutStart(
    list,
    { currency: getCurrency(country, region) },
    createCourseProduct(list, course, country, region, locationType)
  )
