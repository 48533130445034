import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(advancedFormat)

export const date = (dateString: string): Dayjs => {
  return dayjs(dateString).local()
}

export { dayjs }
