import Link from 'next/link'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import { TypeListItem } from '../../types/contentful'
import { Icon } from '../Icon/Icon'

const MiddleAlignedListItem = ({
  listItem,
  index,
}: {
  listItem: TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
  index: number | string
}) => {
  const variant = listItem.fields.variant
  const isv2 = variant === 'v2'
  const iconWrapperClass = `flex items-center justify-center`
  const iconContainerClass = `z-10 relative -mt-6 flex flex-col items-center justify-between h-full`

  const LinkWrapper = ({ children, href }) => {
    return href ? (
      <Link key={`${listItem.sys.id}-${index}`} href={listItem.fields.link} legacyBehavior>
        <div
          className={`relative cursor-pointer`}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if ((e.target as HTMLAnchorElement).href) {
              e.preventDefault()
              window.location.href = (e.target as HTMLAnchorElement).href
              e.stopPropagation()
            }
          }}
        >
          {children}
        </div>
      </Link>
    ) : (
      <>{children}</>
    )
  }

  return (
    <LinkWrapper href={listItem?.fields?.link}>
      <div key={`${listItem.sys.id}-${index}`} className="py-6">
        <div
          className={`relative flow-root rounded-lg px-6 pb-8 ${
            isv2 ? 'md:h-[20rem] lg:h-[18rem]' : ''
          }`}
          style={{
            backgroundColor: listItem.fields.colour,
          }}
        >
          <div className="absolute rounded-lg left-0 top-0 right-0 bottom-0 z-0 bg-primary-ui-white opacity-90" />
          <div className={iconContainerClass}>
            <div
              className={iconWrapperClass}
              style={{
                backgroundColor: 'transparent',
              }}
            >
              <div style={{ color: listItem.fields.colour }}>
                <Icon className="h-10 w-10" aria-hidden="true" name={listItem.fields.icon} />
              </div>
            </div>
            <h3
              className={
                isv2
                  ? 'mt-8 mb-3 text-2xl font-semibold text-center'
                  : 'mt-8 text-lg font-medium tracking-tight mb-5'
              }
            >
              {listItem.fields.title}
            </h3>
            <div
              className={
                isv2
                  ? '[&>*]:text-center [&>h6]:font-secondary [&>h6]:text-lg [&>p]:m-0 flex [&>p>strong]:text-sm [&>p>strong]:font-medium flex-col gap-5'
                  : ''
              }
            >
              {renderDocumentToReactComponents(listItem.fields.content)}
            </div>
          </div>
        </div>
      </div>
    </LinkWrapper>
  )
}

export default MiddleAlignedListItem
