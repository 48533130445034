import { TypeCourse, TypeEvent, TypeHomeCoursesBlock, TypeTag } from '../../types/contentful'
import CategoryDropdown from './CategoryDropdown'
import Link from 'next/link'
import { getCourses, getEvents } from '../../lib/contentful'
import React, { useEffect, useState } from 'react'
import { useLocation } from '../../context/Location/LocationProvider'
import { RenderCard } from '../Card/RenderCard'
import shuffleArray from '../../helpers/arrayRandomise'
import { SSR_LOCALE } from '../../config/locale'
import { useContentfulClient } from '../../context/ContentfulClient/ContentfulClientContext'
import { mergeManyItemFields } from '../../helpers/mergeItemFields'
import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'
import { Course, SocialEvent } from '../../types/graphql'
import { ICourseExtended } from '../../types/types'
import { CourseType } from '../../types/graphql'
import {
  setDisplayedProducts,
  setSelectedProduct,
} from '../../modules/ecommerce/product-data-layer.handler'
import useLocalisedContent from '../../context/Location/useLocalisedContent'
import Container from '../Container/Container'

type HomePageCoursesProps = {
  entry: TypeHomeCoursesBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}
const HomePageCourses = ({ entry }: HomePageCoursesProps) => {
  const [selectedTag, setSelectedTag] = useState<TypeTag<
    'WITHOUT_UNRESOLVABLE_LINKS',
    string
  > | null>(null)
  const [cards, setCards] = useState<Array<ICourseExtended>>([])
  const { courses } = useContentfulResources()
  const { events } = useContentfulResources()

  const localizedCourses = useLocalisedContent(cards)
  const contentfulClient = useContentfulClient()

  const handleSelection = async (tag?: TypeTag<'WITHOUT_UNRESOLVABLE_LINKS', string>) => {
    let shuffledCards = []

    const getEventCards = async () => {
      const eventsResult = await getEvents(contentfulClient, tag ? [tag?.fields?.slug] : [], true)
      const contetfullEvents = events.filter(
        (event: SocialEvent) =>
          eventsResult.map(e => e.fields.slug[SSR_LOCALE]).includes(event.slug) &&
          event?.instances?.length
      )

      const filteredEvents = eventsResult.filter(
        (event: TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>) =>
          contetfullEvents.map(e => e.slug).includes(event.fields.slug[SSR_LOCALE])
      )

      return shuffleArray(mergeManyItemFields(filteredEvents, [], contetfullEvents)).slice(0, 4)
    }

    const getCourseCards = async () => {
      const courseResults = await getCourses(contentfulClient, tag ? [tag?.fields?.slug] : [])
      const contetfullCourses = courses.filter(
        (course: Course) =>
          courseResults.map(c => c.fields.slug[SSR_LOCALE]).includes(course.slug) &&
          course?.publicIntakes?.length &&
          !course.title.includes('Sampler') &&
          course.type !== CourseType.FreeCourse
      )

      const filteredCourses = courseResults.filter(
        (course: TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>) =>
          contetfullCourses.map(c => c.slug).includes(course.fields.slug[SSR_LOCALE])
      )

      return shuffleArray(mergeManyItemFields(filteredCourses, contetfullCourses, [])).slice(0, 4)
    }

    setSelectedTag(tag)

    if (entry.fields?.showEvent && entry.fields?.showCourses) {
      // if both are selected, show 3 courses and 1 event
      const courses = await getCourseCards()
      const events = await getEventCards()
      shuffledCards =
        events.length > 0 ? shuffleArray([...courses.slice(0, 3), events[0]]) : courses
    } else if (entry.fields?.showCourses && !entry.fields?.showEvent) {
      const courses = await getCourseCards()
      shuffledCards = courses
    } else if (entry.fields?.showEvent && !entry.fields?.showCourses) {
      const events = await getEventCards()
      shuffledCards = events
    } else {
      // if neither are selected, show courses
      const courses = await getCourseCards()
      shuffledCards = courses
    }

    shuffledCards.forEach(card => {
      card.fields.size = {
        [SSR_LOCALE]: 'Small',
      }

      card.fields.shape = {
        [SSR_LOCALE]: 'Square',
      }
    })

    setCards(shuffledCards)
  }

  useEffect(() => {
    handleSelection(null)
  }, [])

  const { country, region } = useLocation()

  useEffect(() => {
    setDisplayedProducts({
      type: 'home',
      products: localizedCourses,
      country,
      region,
    })
  }, [country, region, localizedCourses])

  return (
    <Container
      className={'full-width px-12 py-10 '}
      style={{
        backgroundColor: entry?.fields?.backgroundColour,
      }}
    >
      <div className="mx-auto max-w-[90rem]">
        <div className={'absolute top-0 left-0 bottom-16 sm:bottom-6 right-0 -z-10'} />
        <h3 className={'w-full text-center text-3xl md:text-4xl font-bold font-primary '}>
          I want to learn about
          <CategoryDropdown
            tags={entry?.fields?.tags}
            selectedTag={selectedTag}
            onSelection={handleSelection}
          />
        </h3>
        <div className="full-width sm:constrained-width grid grid-flow-col pl-[10%] sm:pl-1 auto-cols-[minmax(90%,_1fr)] sm:auto-cols-[minmax(20rem,_1fr)] gap-2 md:gap-1 mt-10 sm:max-w-full overflow-x-scroll">
          {localizedCourses.map((item, index) => (
            <RenderCard
              key={`${item.sys.id}`}
              item={item}
              onCardClick={() =>
                setSelectedProduct({ type: 'home', country, index, product: item })
              }
            />
          ))}
        </div>
        <div className={'flex w-full justify-center items-center mt-5'}>
          <Link
            href={`/browse/courses${selectedTag ? `/${selectedTag.fields.slug}` : ''}`}
            legacyBehavior
          >
            <div
              className={
                'py-2 px-4 rounded-full bg-secondary-ui-900 text-white font-secondary cursor-pointer hover:opacity-75'
              }
            >
              Show me more
            </div>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default HomePageCourses
