import Fullscreen from '../HeroSlider/Fullscreen'
import { Icon } from '../Icon/Icon'
import ReactPlayer from 'react-player/lazy'
type FullscreenVideoProps = {
  onClose: () => void
  url: string
}

const FullscreenVideo = ({ onClose, url }: FullscreenVideoProps) => {
  return (
    <Fullscreen>
      <div className="z-[200] fixed left-0 right-0 top-0 bottom-0 flex bg-black/30">
        <div className="m-10 mx-auto my-auto w-[80%] h-[50%] md:h-[80%] relative" onClick={onClose}>
          <button
            type="button"
            onClick={onClose}
            className="cursor-pointer gap-2 bg-black text-white border-1 flex-row px-2 py-1 text-sm border-white absolute right-0 top-[-45px] flex items-center rounded-full transition-colors duration-300"
          >
            Close video <Icon className="w-3 h-3" name={'Close'} />
          </button>
          <ReactPlayer width={'100%'} height={'100%'} url={url} controls={true} playing={false} />
        </div>
      </div>
    </Fullscreen>
  )
}

export default FullscreenVideo
