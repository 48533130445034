import { useCallback } from 'react'

const useCopyToClipboard = () => {
  const copyToClipboard = useCallback(text => {
    if (typeof window !== 'undefined') {
      navigator.clipboard.writeText(text)
    }
  }, [])

  return copyToClipboard
}

export default useCopyToClipboard
