import { TypeImageWithFocalPoint } from '../../types/contentful'
import React from 'react'
import Image from 'next/legacy/image'

type ImageWithFocalPointBlockProps = {
  entry: TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const ImageWithFocalPointBlock = ({ entry }: ImageWithFocalPointBlockProps) => {
  // Validate file is valid
  const file = entry?.fields?.image?.fields?.file
  const image = file?.details?.image
  if (!file || !image) {
    return null
  }
  return (
    <div className="relative w-full mb-4" id="image">
      <Image
        src={`https:${file.url}`}
        alt={entry.fields.title || entry.fields.image.fields.title}
        sizes="42rem"
        width={image.width}
        height={image.height}
      />
      {entry.fields.caption && <p className="text-sm my-2">{entry.fields.caption}</p>}
    </div>
  )
}

export default ImageWithFocalPointBlock
