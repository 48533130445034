import React from 'react'
import { TypeGridIconItemContentBlock } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import { Icon } from '../Icon/Icon'
import classNames from 'classnames'

type GridIconItemContentBlockProps = {
  entry: TypeGridIconItemContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const GridIconItemContentBlock = ({ entry }: GridIconItemContentBlockProps) => {
  return (
    <div
      className={`flex gap-2 justify-center text-center md:text-left ${
        !entry.fields?.icon ? 'md:block' : ''
      }`}
    >
      <Icon
        className={`h-6 w-6 ${
          entry.fields.icon === 'CheckCircleSolid' ? 'text-accent-4-600' : 'text-primary-ui-black'
        }`}
        aria-hidden="true"
        name={entry.fields.icon}
      />

      <div
        className={classNames(
          'flex flex-col md:block w-full',
          entry.fields?.icon
            ? 'items-start lg:items-center [&>*]:text-left [&>p]:mb-2'
            : 'items-center'
        )}
      >
        {renderDocumentToReactComponents(entry.fields.content)}
      </div>
    </div>
  )
}

export default GridIconItemContentBlock
