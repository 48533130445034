import React from 'react'
import { TypeLogoBannerBlock } from '../../types/contentful'
import Image from 'next/legacy/image'
import Link from 'next/link'
import PreTitle from '../TitleBlock/PreTitle'
import SVG from 'react-inlinesvg'
import Container from '../Container/Container'

type LogoBannerBlockProps = {
  entry: TypeLogoBannerBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const LogoBannerBlock = ({ entry }: LogoBannerBlockProps) => {
  const providers = entry?.fields?.providers

  const size = entry?.fields?.size === 'lg' ? 'max-w-8xl' : 'max-w-3xl'
  const svgSize = entry?.fields?.size === 'lg' ? 'h-[6rem] md:h-[6rem]' : 'h-[4rem] md:h-[4rem]'

  const LinkWrapper = ({ href, children }: { href: string; children: React.ReactNode }) => {
    return href ? (
      <Link href={href} legacyBehavior>
        {children}
      </Link>
    ) : (
      <>{children}</>
    )
  }

  return providers && providers.length > 0 ? (
    <Container className="mt-10 mb-6 md:my-10" id={entry?.sys?.id}>
      {entry?.fields?.title && <PreTitle align="center" title={entry?.fields?.title} />}
      <div
        className={`${size} w-screen md:w-auto mx-[-50vw] relative left-[50%] right-[50%] md:static md:mx-auto basis-0 flex flex-col md:flex-row justify-items-center md:items-end md:justify-center gap-6 md:gap-24`}
      >
        {providers.map((provider, index) => {
          const providerLogoFields = provider?.fields?.logo?.fields
          const providerIconFields = provider?.fields?.icon?.fields

          const bgColor = provider?.fields?.bgColor
          const cssVars = {
            '--bg-color': `${bgColor}`,
          } as React.CSSProperties

          return (
            <div
              key={`${provider.sys.id}-${index}`}
              className="bg-[--bg-color] w-full  md:bg-transparent px-[1rem] py-6 md:p-0 flex items-center justify-center"
              style={cssVars}
            >
              {!providerIconFields?.file?.url && providerLogoFields.file?.url ? (
                <Link href={provider?.fields?.url || ''}>
                  <Image
                    src={`https:${providerLogoFields.file.url}`}
                    alt={providerLogoFields.title || provider.fields.name}
                    width={providerLogoFields.file.details.image.width}
                    height={providerLogoFields.file.details.image.height}
                    className="w-full h-auto"
                  />
                </Link>
              ) : (
                <LinkWrapper href={provider?.fields?.url || ''}>
                  <a>
                    <div
                      className={`[&>*>*]:text-[black] w-full ${svgSize} flex items-center ${
                        provider?.fields?.url
                          ? '[&>*>*]:hover:text-accent-4-700 hover:animate-jump animate-once animate-duration-200'
                          : ''
                      }`}
                    >
                      <SVG
                        color="black"
                        src={`https:${providerIconFields?.file?.url}`}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </a>
                </LinkWrapper>
              )}
            </div>
          )
        })}
      </div>
    </Container>
  ) : null
}

export default LogoBannerBlock
