import React from 'react'
import { TypeButton, TypeHeroBlock, TypeImageWithFocalPoint } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import Image from 'next/legacy/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import { isContentfulType } from '../../lib/contentful'
import { H1 } from '../Typography/Typography'
import ReactMarkdown from 'react-markdown'
import Container from '../Container/Container'

type HeroBannerProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const HeroBlockV2 = ({
  entry: {
    fields: {
      backgroundColor,
      fontColor,
      content,
      ctaButtons,
      title,
      postTitle,
      ctaButtonsAlignment,
    },
  },
}: HeroBannerProps) => {
  const focalRatio = getFocalRatio(
    content?.[0]?.fields as TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']
  )

  const bgColor = backgroundColor || 'transparent'
  const fntColor = fontColor || 'white'

  return (
    <Container>
      <div className={`mt-navbar sm:mt-navbar h-[38rem] sm:h-[40rem] flex flex-col justify-end`}>
        <div
          className={
            'absolute h-[25rem] sm:h-[40rem] z-0 mt-navbar-sm sm:mt-navbar top-0 left-0 right-0 bottom-0'
          }
        >
          {isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
            content?.[0],
            'imageWithFocalPoint'
          ) && (
            <Image
              src={`https:${content?.[0].fields.image.fields.file.url}`}
              alt={content?.[0].fields.caption || content?.[0].fields.title}
              layout="fill"
              objectFit="cover"
              priority={true}
              objectPosition={`${focalRatio.x}% ${focalRatio.y}%`}
            />
          )}
        </div>
        <div
          className={`sm:container -mt-12 mx-auto sm:mt-0 sm:relative absolute left-0 w-full flex flex-col`}
        >
          <div
            className={` w-full sm:w-fit h-fit lg:h-full p-8`}
            style={{ backgroundColor: bgColor, color: fntColor }}
          >
            <H1
              className={`text-3xl lg:text-6xl ${
                postTitle ? 'text-left' : 'text-center'
              } text-left whitespace-pre-wrap break-words leading-none font-heading mb-5 `}
            >
              {!!title && (
                <ReactMarkdown className="whitespace-pre-wrap text-[2.5rem] lg:text-[3.75rem] leading-[3.75rem] lg:leading-[5.625rem] font-extrabold">
                  {title}
                </ReactMarkdown>
              )}
            </H1>

            <span
              className={`text-xl lg:text-3xl text-left whitespace-pre-wrap break-words leading-none font-heading`}
            >
              {!!postTitle && (
                <ReactMarkdown className="whitespace-pre-wrap text-[1.25rem] lg:text-[1.875rem] leading-[1.875rem] lg:leading-[2.813rem]">
                  {postTitle}
                </ReactMarkdown>
              )}
            </span>

            {!!ctaButtons && (
              <div
                className={`mt-8 flex lg:mb-8 gap-4 ${
                  ctaButtonsAlignment === 'Vertical' ? 'flex-col items-left' : 'flex-wrap'
                }`}
              >
                {ctaButtons?.map(
                  (ctaButton: TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>, index) => (
                    <div
                      key={`ctaButton-${index}`}
                      className={index > 0 ? `dark md:ml-0 md:mt-0` : ''}
                    >
                      <Button
                        id={ctaButton.sys.id}
                        buttonStyle={contentfulType[ctaButton?.fields?.style]}
                        size={contentfulSize[ctaButton?.fields?.size]}
                        href={ctaButton.fields?.url}
                        variant={contentfulVariant[ctaButton?.fields?.variant]}
                        urlBehaviour={ctaButton?.fields?.urlBehaviour}
                      >
                        {ctaButton.fields?.label}
                        {ctaButton.fields?.icon && (
                          <Icon className="ml-1 w-4 h-4" name={ctaButton.fields.icon} />
                        )}
                      </Button>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default HeroBlockV2
