import { TypeHeroSlide, TypeVideo } from '../../types/contentful'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { getShapeClass } from '../Shapes/Shapes'
import Image from 'next/legacy/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import { Icon } from '../Icon/Icon'
import FullscreenVideo from '../FullscreenVideo/FullscreenVideo'
import PodcastBlock from '../VideoBlock/PodcastBlock'
import { MediaType } from '../../types/types'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type VideoBlockProps = {
  block: TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
  shape?: TypeHeroSlide<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['shape'] | 'Cube Masters'
  className: string
}

const VideoSlide = ({ block, shape, className }: VideoBlockProps) => {
  // Allow custom image to be passed in
  const [fullscreen, setFullscreen] = useState(false)

  // Omit orphaned slides
  if (!block.fields) {
    return null
  }

  const focalRatio = getFocalRatio(block?.fields?.image?.fields)
  return (
    <>
      {block.fields.variant === MediaType.PODCAST ? (
        <PodcastBlock block={block} />
      ) : block?.fields?.image ? (
        <a
          className="cursor-pointer"
          onClick={event => {
            event.preventDefault()
            setFullscreen(true)
          }}
        >
          <div
            className={`relative ${className} ${getShapeClass('Large Square', shape)}`}
            id={block?.sys?.id}
          >
            <Image
              src={`https:${block.fields.image.fields.image.fields.file.url}`}
              alt={
                block.fields.image.fields.image.fields.description ||
                block.fields.image.fields.image.fields.title
              }
              layout="fill"
              objectFit="cover"
              objectPosition={`${focalRatio.x}% ${focalRatio.y}%`}
            />
            <Icon
              name={'Play'}
              className="w-12 h-30 absolute left-[50%] top-[50%] ml-[-30px] mt-[-30px] text-white"
            />
          </div>
        </a>
      ) : (
        <div className={`${className} flex items-center`}>
          <div className="relative pt-[56.25%] h-0 w-full">
            <ReactPlayer
              className="absolute top-0 left-0"
              url={block.fields.video}
              controls={true}
              playing={false}
              autoplay={false}
              width={'100%'}
              height={'100%'}
            />
          </div>
        </div>
      )}
      {fullscreen && (
        <FullscreenVideo onClose={() => setFullscreen(false)} url={block.fields.video} />
      )}
    </>
  )
}

export default VideoSlide
