import { TypeVideo } from '../../types/contentful'
import dynamic from 'next/dynamic'
import Container from '../Container/Container'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type VideoBlockDefaultProps = {
  block: TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const VideoBlockDefault = ({ block }: VideoBlockDefaultProps) => {
  // Allow custom image to be passed in
  const light = block.fields?.image?.fields?.image?.fields?.file?.url || false
  return (
    <Container className="w-full my-6">
      <div className="relative pt-[56.25%]">
        <ReactPlayer
          className="absolute top-0 left-0"
          playing={false}
          url={block.fields.video}
          controls={true}
          width={'100%'}
          height={'100%'}
          light={light}
        />
      </div>
    </Container>
  )
}

export default VideoBlockDefault
