import { TypeImageWithFocalPoint } from '../types/contentful'

export type Ratio = {
  x: number
  y: number
}

/**
 * Return a focal ratio for this image.
 * Note that if an invalid image is requested, it will always return a center (50-50) ratio by default
 *
 * @param imageWithFocalPoint
 * @return Ratio with x and y properties
 */
const GetFocalRatio = (
  imageWithFocalPoint?: TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']
): Ratio => {
  const focalPoint = (imageWithFocalPoint?.focalPoint as { focalPoint: Ratio })?.focalPoint
  const image = imageWithFocalPoint?.image?.fields?.file?.details?.image

  // There is either no image, or no focal point, so default to center
  if (
    !image ||
    !focalPoint ||
    focalPoint.x === null ||
    focalPoint.y === null ||
    !image.width ||
    !image.height
  ) {
    return { x: 50, y: 50 }
  }

  // Third case, we have image with focal point
  return {
    x: (focalPoint.x / image.width) * 100,
    y: (focalPoint.y / image.height) * 100,
  }
}

export default GetFocalRatio
