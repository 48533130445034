import { Entry } from 'contentful'
import { isContentfulType } from '../lib/contentful'
import {
  TypeImageWithFocalPoint,
  TypeImageWithFocalPointSkeleton,
  TypeVideo,
  TypeVideoSkeleton,
} from '../types/contentful'

export const getImageWithFocalPoint = (
  content:
    | TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | Entry<
        TypeImageWithFocalPointSkeleton | TypeVideoSkeleton,
        'WITHOUT_UNRESOLVABLE_LINKS',
        string
      >
): TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string> => {
  if (
    isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      content,
      'imageWithFocalPoint'
    )
  ) {
    return content
  }
  if (isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(content, 'video')) {
    return content.fields.image
  }
  return null
}
