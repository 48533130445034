import React from 'react'
import { TypeNavigationMenu, TypeResourceSet } from '../../types/contentful'
import { isContentfulType } from '../../lib/contentful'
import FooterGroup from './FooterGroup'
import FooterSocialIcons from './FooterSocialIcons'
import isDarkColor from '../../helpers/isDarkColor'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'

type FooterDefaultProps = {
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  colour?: string
  bgColourClass?: string
  customClass?: string
}

const FooterDefault = ({ menu, colour, bgColourClass, customClass }: FooterDefaultProps) => {
  const { footerInfo, footerCopyright, footerIconImage, footerIconImage2 } = menu?.fields ?? {}
  const resources = [...(menu?.fields.resources || [])]
  const footerIconImageUrl = menu?.fields?.footerIconImage?.fields?.file?.url
  const footerIconImage2Url = menu?.fields?.footerIconImage2?.fields?.file?.url
  const social =
    resources[resources.length - 1]?.fields?.title === 'Social' ? resources.pop() : undefined
  let containerClasses = customClass ? customClass : 'p-10'
  if (!bgColourClass) {
    if (!colour) containerClasses += ' bg-primary-brand '
    if (colour && isDarkColor(colour)) containerClasses += ' dark'
  } else {
    containerClasses += ` ${bgColourClass}`
  }

  return (
    <footer className={containerClasses} style={{ background: colour }}>
      <div className="mx-auto container px-6 dark:text-white">
        <div className="flex flex-col md:flex-row md:gap-6 border-b-[1px] border-primary-ui-900 dark:border-white">
          <div className="block min-w-[33%] w-full md:w-auto md:flex lg:pb-0 lg:mb-0 md:mb-4 md:py-6">
            <div
              className={
                'flex flex-col gap-6 items-center md:items-start text-primary-ui-900 dark:text-white font-light text-center md:text-left'
              }
            >
              <div className="flex flex-col gap-6 mb-6">
                <div>
                  <span className="font-thin">BROUGHT TO YOU BY </span>
                </div>
                {footerIconImage2 && (
                  <div className="mx-auto md:mx-0 max-w-[11rem]">
                    <img className="max-h-[3rem]" src={footerIconImageUrl}></img>
                  </div>
                )}
                {footerIconImage && (
                  <div className="mx-auto md:mx-0 max-w-[11rem]">
                    <img className="max-h-[3rem]" src={footerIconImage2Url}></img>
                  </div>
                )}
              </div>
              {!!footerInfo && (
                <address className="mt-4 [&>p]:m-1 not-italic">
                  {renderDocumentToReactComponents(footerInfo)}
                </address>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-1 md:flex-row md:gap-x-12 w-full py-6 md:flex-wrap justify-end max-full lg:mr-5">
            {resources.map(menuItem => {
              if (
                isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  menuItem,
                  'navigationMenu'
                )
              ) {
                return <FooterGroup key={menuItem.sys.id} menu={menuItem} />
              }
            })}
          </div>
        </div>

        <div className="flex py-6 flex-col md:flex-row">
          <div
            className={
              'flex item-center justify-center md:items-start md:justify-start w-full md:w-1/2 text-center md:text-left py-2'
            }
          >
            {`© ${new Date().getFullYear()} ${footerCopyright}`}
          </div>
          <div
            className={
              'flex w-full md:w-1/2 items-center justify-center md:items-end md:justify-end text-center md:text-right py-2'
            }
          >
            {isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
              social,
              'navigationMenu'
            ) && <FooterSocialIcons menu={social} />}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterDefault
