import React, { useMemo } from 'react'
import { TypeQuoteContentBlock } from '../../types/contentful'
import QuoteContentBlockDefault from './QuoteContentBlockDefault'
import QuoteContentBlockV2 from './QuoteContentBlockV2'

export type QuoteContentBlockProps = {
  entry: TypeQuoteContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const QuoteContentBlock = ({ entry }: QuoteContentBlockProps) => {
  const { variant } = entry.fields

  const renderVariant = useMemo(() => {
    switch (variant) {
      case 'v2':
        return <QuoteContentBlockV2 entry={entry} />
      case 'Default':
      default:
        return <QuoteContentBlockDefault entry={entry} />
    }
  }, [entry])

  return renderVariant
}

export default QuoteContentBlock
