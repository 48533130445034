import React from 'react'

type PreTitleProp = {
  align: 'center' | 'right' | 'left'
  title: string
}

const PreTitle = ({ title, align }: PreTitleProp) => {
  return (
    <p
      className={`uppercase text-secondary-ui-600 dark:text-primary-ui-white font-inter mb-5 ${
        align === 'center'
          ? 'text-center'
          : align === 'right'
          ? 'text-center md:text-right'
          : 'text-center md:text-left'
      }`}
    >
      {title}
    </p>
  )
}

export default PreTitle
