import { TypeNavigationMenu } from '../../types/contentful'
import React from 'react'
import FooterItem from './FooterItem'
import Link from 'next/link'

/**
 * Group of items
 * @param menu
 * @constructor
 */
const FooterGroup = ({
  menu,
}: {
  menu: TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>
}) => {
  const MenuTitle = menu?.fields?.link ? (
    <Link href={menu?.fields?.link}>
      <strong className={'text-center md:text-left w-full block md:flex'}>
        {menu?.fields?.title}
      </strong>
    </Link>
  ) : (
    <strong className={'text-center md:text-left w-full block md:flex'}>
      {menu?.fields?.title}
    </strong>
  )

  return (
    <div className={'flex mb-10 flex-col min-w-[10rem]'}>
      {MenuTitle}
      <ul
        className={'p-0 m-0 mt-3 list-none block md:flex flex-col flex-wrap justify-center gap-2'}
      >
        {menu?.fields?.items?.map(item => (
          <FooterItem key={item.sys.id} item={item} />
        ))}
      </ul>
    </div>
  )
}

export default FooterGroup
