import { EntryLinkBlock } from '@contentful/rich-text-types/dist/types/nodeTypes'
import { Entry } from 'contentful'
import { Course, SocialEvent } from './graphql'

// Same as a link, but with the target embedded directly instead of linked
export interface EntryEmbeddedBlock extends Omit<EntryLinkBlock, 'data'> {
  data: {
    target: Entry<any>
  }
}

export interface GraphqlResources {
  courses: Array<Course>
  events: Array<SocialEvent>
  useSection?: boolean
}

export type CardSize = 'Small Square' | 'Medium Wide' | 'Medium Tall' | 'Large Square'

export enum DeliveryMethods {
  Online = 'ONLINE',
  Blended = 'BLENDED',
  Location = 'PHYSICAL',
}

export type CardShape = 'Bolt' | 'Bubble' | 'Cube' | 'Tab'

export enum CardShapes {
  Bolt = 'Bolt',
  Bubble = 'Bubble',
  Cube = 'Cube',
  Tab = 'Tab',
}

export enum HeroBlockShapes {
  Razor = 'Razor',
  None = 'None',
}

export enum ButtonURLBehaviours {
  CurrentTab = 'Current Tab',
  NewTab = 'New Tab',
}

export enum GridContentBlockMobileDisplay {
  ThreeAtATime = '3 at a time',
  Default = 'Default',
}
