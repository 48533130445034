import React, { useMemo } from 'react'
import { TypeMarketoFormButtonBlock } from '../../types/contentful'
import HubspotFormButtonBlock from './HubspotFormButtonBlock'
import { HubspotEmbeddedForm } from './HubspotEmbeddedForm'
import { contentfulSize, contentfulType } from '../Button/Button'
import { EmbeddedFormTypes } from '../../types/types'

type MarketoFormButtonBlockProps = {
  entry: TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  mobile?: boolean
}

const MarketoFormButtonBlock = ({ entry, mobile }: MarketoFormButtonBlockProps) => {
  const {
    formType,
    title,
    buttonLabel,
    style,
    size,
    icon,
    marketoFormId,
    portalId,
    displayTitle,
    buttonColor,
    buttonHoverColor,
  } = entry.fields || {}

  const componentMap = {
    [EmbeddedFormTypes.HUBSPOT_FORM]: (
      <div className={`container mx-auto py-3 md:py-6 lg:py-12 max-w-4xl`}>
        <HubspotEmbeddedForm
          portalId={portalId}
          formId={marketoFormId}
          title={title}
          displayTitle={displayTitle}
        />
      </div>
    ),
    [EmbeddedFormTypes.HUBSPOT_FORM_MODAL]: (
      <HubspotFormButtonBlock
        portalId={portalId}
        formId={marketoFormId}
        title={title}
        style={contentfulType[style]}
        size={contentfulSize[size]}
        buttonLabel={buttonLabel}
        buttonColor={buttonColor}
        icon={icon}
        buttonHoverColor={buttonHoverColor}
        hideIconMobile={mobile}
      />
    ),
  }

  const renderedForm = useMemo(
    () => componentMap[formType] || <></>,
    [formType, componentMap, entry, portalId, marketoFormId, title, style, size, buttonLabel, icon]
  )

  return <>{renderedForm}</>
}

export default MarketoFormButtonBlock
