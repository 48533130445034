import getFocalRatio from '../../helpers/getFocalRatio'
import Quote from '../Icons/Quote'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { QuoteContentBlockProps } from './QuoteContentBlock'

const QuoteContentBlockDefault = ({ entry }: QuoteContentBlockProps) => {
  const { copy, person } = entry.fields

  const photo = getImageWithFocalPoint(person?.fields?.hero)

  const focalRatio = getFocalRatio(photo?.fields)

  const renderFooter = (
    <div
      className={
        'h-20 sm:h-16 md:h-20 lg:h-16 relative flex items-center bg-secondary-ui-50 p-5 cursor-pointer mt-4 sm:mt-16'
      }
    >
      {photo?.fields?.image?.fields?.file?.url && (
        <div
          className={`relative w-16 h-16 sm:w-20 sm:h-20 rounded-full overflow-clip border-primary-ui-white border-2 shrink-0`}
        >
          <Image
            src={`https:${photo.fields.image.fields.file.url}`}
            alt={photo.fields.title}
            layout="fill"
            objectFit="cover"
            objectPosition={`${focalRatio?.x || 50}% ${focalRatio?.y || 50}%`}
            // For viewports greater than 512px set the image width to 5.25rem (84px) else 3.25rem (52px)
            sizes="(max-width: 512px) 3.25rem, 5.25rem"
          />
        </div>
      )}
      <span className={'capitalize text-primary-ui-900 ml-3'}>
        <span className={`font-bold`}>
          {person?.fields?.firstName} {person?.fields?.lastName}{' '}
        </span>
        <span className={'text-secondary-ui-500 ml-3'}>{person?.fields?.title}</span>
      </span>
    </div>
  )

  return (
    <div className={'w-full my-5'}>
      <div>
        <Quote color="#CCCCCC" className="mb-2" />
        <p className={'text-lg italic font-medium text-secondary-ui-700'}>{copy}</p>
        <Quote color="#CCCCCC" className="scale-x-[-1]" />
      </div>
      {person?.fields && (
        <Link href={`/people/${person.fields.slug}`} legacyBehavior>
          {renderFooter}
        </Link>
      )}
    </div>
  )
}

export default QuoteContentBlockDefault
