import React from 'react'
import { TypeCardHighlightContentBlock, TypeButton } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import isDarkColor from '../../helpers/isDarkColor'
import TitleBlock from '../TitleBlock/TitleBlock'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { isContentfulType } from '../../lib/contentful'
import { CardShapes } from '../../types/content'
import Container from '../Container/Container'

type CardHighlightContentBlockProps = {
  entry: TypeCardHighlightContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  CTACardCourse?: JSX.Element
  isCourseV2?: boolean
}

const CardHighlightContentBlockV2 = ({
  entry,
  CTACardCourse,
  isCourseV2,
}: CardHighlightContentBlockProps) => {
  const {
    preTitle,
    title,
    backgroundColour,
    backgroundShape,
    leftColumn,
    rightColumn,
    headingColor,
    ctaButton,
  } = entry.fields

  const containerClasses = `full-width py-3 md:py-6 lg:py-12 ${
    backgroundColour && isDarkColor(backgroundColour) ? 'text-white dark' : ''
  }`

  const cssVars = {
    '--heading-color': `${headingColor}`,
  } as React.CSSProperties

  const CourseV2RightColumn = (
    <>
      {CTACardCourse && <div className="relative flex-1">{CTACardCourse}</div>}
      {!CTACardCourse?.props?.eoiOnly ? (
        <div className="relative flex-1">{renderDocumentToReactComponents(rightColumn)}</div>
      ) : (
        <div className="h-full flex flex-col [&>*]:relative [&>*]:flex-1 [&>*>*]:top-56 [&>*>*]:sticky">
          {renderDocumentToReactComponents(rightColumn)}
        </div>
      )}
    </>
  )

  return (
    <Container className={containerClasses} id={entry.sys.id}>
      <div
        className={`relative mb-3 pt-14 ${
          backgroundShape === CardShapes.Bolt ? 'container mx-auto' : ''
        }`}
      >
        <div
          className={`absolute top-0 left-0 -bottom-10 right-0 -z-10 ${
            backgroundShape === CardShapes.Bolt ? 'bolt-wide' : ''
          }`}
          style={{ backgroundColor: entry.fields.backgroundColour }}
        />
        <div
          className={`container mx-auto relative grid grid-cols-1 dark:text-primary-ui-white justify-items-end md:gap-14${
            CTACardCourse ? ' lg:grid-cols-3' : ' md:grid-cols-3'
          }`}
        >
          <div
            className={`text-center [&>*]:text-center [&>*]:md:text-start [&>*]:justify-center md:[&>*]:justify-start${
              CTACardCourse ? ' col-span-1 lg:col-span-2 [&>*]:text-start' : ' col-span-2'
            }`}
          >
            <div className="pb-4">
              <TitleBlock
                title={title}
                preTitle={preTitle}
                headingType="h2"
                size={`5xl`}
                customClassname="!text-center md:!text-left"
              />
            </div>
            {renderDocumentToReactComponents(leftColumn)}
            {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
              ctaButton,
              'button'
            ) &&
              !!ctaButton?.fields?.url && (
                <div className="hidden md:block mt-auto">
                  <Button
                    id={ctaButton?.sys?.id}
                    href={ctaButton?.fields?.url}
                    buttonStyle={contentfulType[ctaButton?.fields?.style]}
                    size={contentfulSize[ctaButton?.fields?.size]}
                    variant={contentfulVariant[ctaButton?.fields?.variant]}
                    urlBehaviour={ctaButton?.fields?.urlBehaviour}
                  >
                    {ctaButton?.fields?.label}
                  </Button>
                </div>
              )}
          </div>
          <div
            className={`[&>*]:text-center md:[&>*]:text-start [&>*>*>*>*]:text-center [&>*>*>*>*]:md:text-start mx-auto [&>*>*>*>h1]:text-[--heading-color] ${
              isCourseV2 ? 'h-full flex flex-col' : ''
            }`}
            style={cssVars}
          >
            {isCourseV2 ? CourseV2RightColumn : renderDocumentToReactComponents(rightColumn)}
          </div>
        </div>
        {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(ctaButton, 'button') &&
          !!ctaButton?.fields?.url && (
            <div className="md:hidden mt-auto flex flex-col items-center justify-center">
              <Button
                id={ctaButton?.sys?.id}
                href={ctaButton?.fields?.url}
                buttonStyle={contentfulType[ctaButton?.fields?.style]}
                size={contentfulSize[ctaButton?.fields?.size]}
                variant={contentfulVariant[ctaButton?.fields?.variant]}
              >
                {ctaButton?.fields?.label}
              </Button>
            </div>
          )}
      </div>
    </Container>
  )
}

export default CardHighlightContentBlockV2
