import React from 'react'
import { TypeVideo } from '../../types/contentful'
import Container from '../Container/Container'

type PodcastBlockProp = {
  block: TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const PodcastBlock = ({ block }: PodcastBlockProp) => {
  const url = block.fields?.video

  return (
    <Container className="my-3">
      {url?.includes('https://open.spotify.com/embed') ? (
        <iframe
          style={{ borderRadius: 12 }}
          src={url}
          width="100%"
          height="252"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
          loading="lazy"
        />
      ) : url?.includes('https://embed.podcasts.apple.com') ? (
        <iframe
          allow="autoplay *; encrypted-media *; clipboard-write"
          frameBorder="0"
          height="450"
          style={{ width: '100%', maxWidth: 660, overflow: 'hidden', background: 'transparent' }}
          src={url}
        />
      ) : url?.includes('https://www.podbean.com/') ? (
        <iframe
          frameBorder="0"
          height="315"
          width="100%"
          style={{ border: 'none', minWidth: 'min(100%, 430px)' }}
          src={url}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}

export default PodcastBlock
