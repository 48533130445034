import { BLOCKS, Document } from '@contentful/rich-text-types'
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { baseOptions } from '../../../config/contentful'
import { EmbeddedEntry } from '../../../components/EmbeddedEntry/EmbeddedEntry'
import { EntryEmbeddedBlock } from '../../../types/content'

function renderOptions() {
  return {
    ...baseOptions,
    renderNode: {
      ...baseOptions.renderNode,
      [BLOCKS.EMBEDDED_ENTRY]: (entry: EntryEmbeddedBlock) => {
        const target = entry.data.target
        return <EmbeddedEntry entry={target} />
      },
    },
  } as Options
}

export default function renderDocumentToReactComponents(json: Document) {
  return <>{documentToReactComponents(json, renderOptions())}</>
}
