import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

type HeadBlockProps = {
  noIndex?: boolean
  title?: string
  keywords?: string
  description?: string
  image?: string
}

const HeadBlock = ({ noIndex = false, title, keywords, description, image }: HeadBlockProps) => {
  const router = useRouter()
  const href =
    process.env.NEXT_PUBLIC_BASE_URL &&
    router.asPath &&
    `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      {!!title && <title>{title}</title>}
      {!!title && <meta property="title" content={`${title}`} />}
      {!!keywords && <meta name="keywords" content={`${keywords}`} />}
      {!!description && <meta name="description" content={`${description}`} />}

      <meta property="og:site_name" content="Digital Passport" />
      {!!title && <meta property="og:title" content={`${title}`} />}
      {!!description && <meta property="og:description" content={`${description}`} />}
      {!!image && <meta property="og:image" content={`https:${image}`} />}
      {!!href && <meta property="og:url" content={href} />}

      {!!title && <meta name="twitter:title" content={`${title}`} />}
      {!!description && <meta name="twitter:description" content={`${description}`} />}
      {!!image && <meta name="twitter:image" content={`https:${image}`} />}
      {!!image && <meta name="twitter:card" content={`https:${image}`} />}
    </Head>
  )
}

export default HeadBlock
