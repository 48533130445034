import ButtonBase from '../ButtonBase/ButtonBase'
import classnames from 'classnames'
import React from 'react'
import VideoButton from './VideoButton'
import isDarkColor from '../../helpers/isDarkColor'
import { ButtonURLBehaviours } from '../../types/content'

export type ButtonPropTypes = {
  id?: string
  children: React.ReactNode
  buttonStyle?: 'solid' | 'outline' | 'transparent' | 'primary' | 'whiteOutline'
  size?: 'sm' | 'base' | 'xl'
  hoverColor?: string
  backgroundColor?: string
  textColor?: string
  className?: string
  href?: string
  iconOnly?: boolean
  onClick?: () => void
  title?: string
  target?: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'default' | 'video'
  urlBehaviour?: 'Current Tab' | 'New Tab'
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
  disabled?: boolean
}

export const contentfulType = {
  Solid: 'solid',
  Outline: 'outline',
  Transparent: 'transparent',
  Primary: 'primary',
  'White outline': 'whiteOutline',
} as { [key: string]: ButtonPropTypes['buttonStyle'] }

export const contentfulSize = {
  Small: 'sm',
  Medium: 'base',
  Large: 'xl',
} as { [key: string]: ButtonPropTypes['size'] }

export const contentfulVariant = {
  Default: 'default',
  Video: 'video',
} as { [key: string]: ButtonPropTypes['variant'] }

const Button = ({
  id,
  children,
  buttonStyle = 'solid',
  size = 'base',
  iconOnly = false,
  className,
  variant = 'default',
  disabled = false,
  hoverColor,
  backgroundColor,
  textColor,
  urlBehaviour,
  ...rest
}: ButtonPropTypes) => {
  const cssVars = {
    '--hover-color': `${hoverColor}`,
    '--backgroundColor': `${backgroundColor}`,
    '--textColor': `${textColor}`,
  } as React.CSSProperties

  const solidStyles =
    'bg-primary-ui-black text-primary-ui-white border border-primary-ui hover:bg-primary-ui-black-80' +
    ' dark:bg-primary-ui-white dark:text-primary-ui-black dark:border-primary-ui-white' +
    ' dark:hover:bg-primary-ui-white-80 dark:hover:border-primary-ui-white-80'
  const primaryStyles = 'bg-button-color text-primary-ui-black hover:bg-button-hover-color'
  const outlineStyles =
    'bg-transparent text-primary-ui dark:text-primary-ui-white dark:hover:bg-primary-ui-white' +
    ' dark:hover:text-primary-ui border-primary-ui dark:border-primary-ui-white border hover:bg-primary-ui' +
    ' hover:text-primary-ui-white'
  const transparentStyles =
    'bg-transparent text-primary-ui border hover:underline dark:text-primary-ui-white border-transparent'
  const whiteOutlineStyles =
    'bg-transparent text-primary-ui-white hover:bg-primary-ui-white' +
    ' border-primary-ui-white border hover:text-primary-ui'
  const base = 'px-4 py-2 text-sm'
  const xl = `${iconOnly ? 'h-[3.125rem] w-[3.125rem] px-3.5' : 'px-6'} py-3`

  const buttonStyles = classnames(
    `inline-flex items-center rounded-full justify-center transition-colors duration-300 ${
      disabled ? 'opacity-50 cursor-not-allowed' : ''
    }`,
    className,
    size === 'base' && base,
    size === 'xl' && xl,
    buttonStyle === 'solid' && solidStyles,
    buttonStyle === 'primary' && primaryStyles,
    buttonStyle === 'outline' && outlineStyles,
    buttonStyle === 'transparent' && transparentStyles,
    buttonStyle === 'whiteOutline' && whiteOutlineStyles
  )

  const isHoverDark = isDarkColor(hoverColor)
  const hoverFontColor = isHoverDark
    ? 'hover:!text-primary-ui-white'
    : 'hover:!text-primary-ui-black'

  return (
    <>
      {variant === contentfulVariant.Video ? (
        <VideoButton
          id={id}
          className={`${buttonStyles} ${backgroundColor ? '!bg-[--backgroundColor]' : ''} ${
            hoverColor
              ? `!hover:bg-[--hover-color] dark:hover:bg-[--hover-color] ${hoverFontColor}`
              : ' '
          }`}
          style={cssVars}
          url={rest.href}
        >
          {children}
        </VideoButton>
      ) : (
        <ButtonBase
          id={id}
          className={`${buttonStyles} ${backgroundColor ? '!bg-[--backgroundColor]' : ''} ${
            textColor ? '!text-[--textColor]' : ''
          } ${
            hoverColor
              ? `hover:!bg-[--hover-color] hover:!border-[--hover-color] dark:hover:bg-[--hover-color] ${hoverFontColor}`
              : ' '
          }`}
          style={cssVars}
          {...rest}
          target={urlBehaviour === ButtonURLBehaviours.NewTab ? '_blank' : '_self'}
        >
          {children}
        </ButtonBase>
      )}
    </>
  )
}

export default Button
