import React from 'react'
import { styles } from '../../config/contentful'

export type HeadingProps = {
  children: React.ReactNode
  className?: string
  size?: '9xl' | '8xl' | '7xl' | '6xl' | '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'lg'
  align?: 'left' | 'center' | 'right'
}

export const fontSize = {
  '9xl': 'text-8xl lg:text-9xl',
  '8xl': 'text-7xl lg:text-8xl',
  '7xl': 'text-6xl lg:text-7xl',
  '6xl': 'text-3xl md:text-5xl lg:text-6xl',
  '5xl': 'text-4xl lg:text-5xl',
  '4xl': 'text-3xl lg:text-4xl',
  '3xl': 'text-2xl lg:text-3xl',
  '2xl': 'text-2xl',
  xl: 'text-xl',
  lg: 'text-lg',
}

export const H1 = ({ children, size = '5xl', align = 'left', ...rest }: HeadingProps) => {
  return (
    <h1
      className={`${rest.className || styles.renderNode.heading_1 + ' ' + fontSize[size]} ${
        align ? (align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : '') : ''
      }`}
    >
      {children}
    </h1>
  )
}

export const H2 = ({ children, size = '3xl', align = 'left', ...rest }: HeadingProps) => {
  return (
    <h2
      className={`${rest.className || styles.renderNode.heading_2 + ' ' + fontSize[size]} ${
        align ? (align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : '') : ''
      }`}
    >
      {children}
    </h2>
  )
}

export const H3 = ({ children, size = '2xl', align = 'left', ...rest }: HeadingProps) => {
  return (
    <h3
      className={`${rest.className || styles.renderNode.heading_3 + ' ' + fontSize[size]} ${
        align ? (align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : '') : ''
      }`}
    >
      {children}
    </h3>
  )
}

export const H4 = ({ children, size = '2xl', align = 'left', ...rest }: HeadingProps) => {
  return (
    <h4
      className={`${rest.className || styles.renderNode.heading_4 + ' ' + fontSize[size]} ${
        align ? (align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : '') : ''
      }`}
    >
      {children}
    </h4>
  )
}

export const H5 = ({ children, size = '2xl', align = 'left', ...rest }: HeadingProps) => {
  return (
    <h5
      className={`${rest.className || styles.renderNode.heading_5 + ' ' + fontSize[size]} ${
        align ? (align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : '') : ''
      }`}
    >
      {children}
    </h5>
  )
}

export const H6 = ({ children, size = '2xl', align = 'left', ...rest }: HeadingProps) => {
  return (
    <h6
      className={`${rest.className || styles.renderNode.heading_6 + ' ' + fontSize[size]} ${
        align ? (align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : '') : ''
      }`}
    >
      {children}
    </h6>
  )
}

export const Bold = ({ children }) => {
  return <strong className={styles.renderMark.bold}>{children}</strong>
}

export const Italic = ({ children }) => {
  return <em className={styles.renderMark.italic}>{children}</em>
}

export const Underline = ({ children }) => {
  return <u className={styles.renderMark.underline}>{children}</u>
}

export const PreTitle = ({ children }) => {
  return <p className="uppercase mb-0.5">{children}</p>
}

export const SubTitle = ({ children }) => {
  return <p className="text-xl">{children}</p>
}
