import { ProductList } from './product-list.model'
import { Product } from './product.model'
import { ICourseExtended } from '../../../types/types'
import * as memoized from 'memoizee'
import { getCoursePriceByCurrency, getLocalizedFields } from '../../../lib/locale'

const createProduct = (
  listId: ProductList,
  course: ICourseExtended,
  country: string,
  region?: string,
  index?: number,
  locationType?: string
) => {
  const fields = getLocalizedFields<ICourseExtended>(course, country)?.fields

  const categories = {}
  fields?.tags
    ?.map(tag => tag?.fields?.label)
    ?.forEach((tag, index) => {
      categories[`item_category${index === 0 ? '' : index + 1}`] = tag
    })

  const {
    priceUSOnline,
    priceUSOnshore,
    priceNZDomestic,
    priceNZOnshore,
    priceAUOnline,
    priceAUOnshore,
  } = fields?.pricingOptions || {}

  const price = getCoursePriceByCurrency({
    country,
    region,
    priceUSOnline,
    priceUSOnshore,
    priceNZDomestic,
    priceNZOnshore,
    priceAUOnline,
    priceAUOnshore,
    locationType,
  })

  const product = {
    ...listId,
    item_id: fields.id,
    item_name: fields.title,
    item_brand: fields.courseProvider?.title,
    ...categories,
    price: price,
    quantity: 1,
  } as Product
  if (index) {
    product.index = index
  }
  return product
}
export const createCourseProduct = memoized(createProduct)

const createProducts = (
  listId: ProductList,
  courses: ICourseExtended[],
  country: string,
  region?: string
): Product[] => {
  return courses.map((course, index) => createCourseProduct(listId, course, country, region, index))
}
export const createCourseProducts = memoized(createProducts)
