import { TypeNavigationItem } from '../../types/contentful'
import React from 'react'
import MenuLink from './MenuLink'

type MenuItemProps = {
  item: TypeNavigationItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
  className?: string
  handleClose?: () => void
  activeSlug?: string
  hoverColor: string
  type?: 'footer' | 'menu' | 'navbar'
}

const MenuItem = ({
  item,
  className,
  handleClose,
  activeSlug,
  hoverColor,
  type = 'navbar',
}: MenuItemProps) => {
  return item?.fields?.link ? (
    <li className={`flex flex-row last:mb-0 ${className} `}>
      <MenuLink
        item={item}
        handleClose={handleClose}
        activeSlug={activeSlug}
        type={type}
        hoverColor={hoverColor}
      />
    </li>
  ) : null
}

export default MenuItem
