import { TypeAccordionItem } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import React, { useCallback, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import classnames from 'classnames'
import { Icon } from '../Icon/Icon'

type AccordionBlockItemProps = {
  item: TypeAccordionItem<'WITHOUT_UNRESOLVABLE_LINKS', string>
  openByDefault?: boolean
}

const SPEED = 400

const AccordionBlockItem = ({ item, openByDefault }: AccordionBlockItemProps) => {
  const [open, setOpen] = useState(openByDefault)
  const [animating, setAnimating] = useState<boolean>(false)
  const animate = useDebouncedCallback(() => setAnimating(false), open ? 0 : SPEED)

  const handleClick = useCallback(() => {
    if (animating) return
    setAnimating(true)
    setOpen(!open)
    animate()
  }, [animating, setAnimating, setOpen, open, animate])

  const contentClass = useMemo(
    () =>
      classnames(
        'overflow-hidden duration-300 transition-all',
        animating && 'max-h-60',
        !open && !animating && 'max-h-0'
      ),
    [animating, open]
  )

  return (
    <div className="border-t-2 border-primary-ui-black-20 dark:border-primary-ui-white-20">
      <button
        id={item?.sys?.id}
        onClick={handleClick}
        className={'relative block w-full text-left py-5 my-1 font-medium'}
      >
        <p className="w-[95%]">{item?.fields?.title}</p>
        <Icon
          name={open ? 'ToggleUp' : 'ToggleDown'}
          className="absolute right-0 top-3 text-primary-ui-black-50 dark:text-primary-ui-white-50"
        />
      </button>
      <div className={contentClass}>
        <div>{renderDocumentToReactComponents(item?.fields?.content)}</div>
      </div>
    </div>
  )
}

export default AccordionBlockItem
