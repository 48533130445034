import React, { useMemo } from 'react'
import { TypeCardHighlightContentBlock } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import isDarkColor from '../../helpers/isDarkColor'
import TitleBlock from '../TitleBlock/TitleBlock'
import Container from '../Container/Container'

type CardHighlightContentBlockProps = {
  entry: TypeCardHighlightContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const CardHighlightContentBlockDefault = ({ entry }: CardHighlightContentBlockProps) => {
  const { preTitle, title, backgroundColour, leftColumn, rightColumn, alignment } = entry.fields
  const containerClasses = `full-width py-3 md:py-6 lg:py-12 ${
    backgroundColour && isDarkColor(backgroundColour) ? 'text-white dark' : ''
  }`
  const showImageAbove = useMemo(() => {
    const isImageOnRight = rightColumn?.content?.some(block => block?.data?.target?.fields?.image)
    return isImageOnRight && (!title || title === ' ') && !preTitle
  }, [])

  return (
    <Container
      className={containerClasses}
      style={{ backgroundColor: entry.fields.backgroundColour }}
    >
      {(alignment === 'Top Left' || alignment === 'Top Right') && (
        <div
          className={`container mx-auto ${alignment === 'Top Right' ? 'md:ml-[50%] md:w-1/2' : ''}`}
        >
          <TitleBlock
            title={title}
            preTitle={preTitle}
            headingType="h2"
            align={`${
              alignment === 'Top Left' ? 'left' : alignment === 'Top Right' ? 'left' : null
            }`}
            size={`5xl`}
          />
        </div>
      )}
      <div
        className={`container mx-auto relative grid grid-cols-1 md:grid-cols-2 dark:text-primary-ui-white gap-x-14 ${
          alignment === 'Top Left' || alignment === 'Top Right' ? 'items-top' : 'items-center'
        }`}
      >
        <div className={`${showImageAbove ? 'order-last md:order-1' : ''}`}>
          {alignment === 'Center Left' && (
            <TitleBlock
              title={title}
              preTitle={preTitle}
              headingType="h2"
              align="left"
              size={`5xl`}
            />
          )}
          {renderDocumentToReactComponents(leftColumn)}
        </div>
        <div className={`${showImageAbove ? 'order-1 md:order-2' : ''}`}>
          {alignment === 'Center Right' && (
            <TitleBlock
              title={title}
              preTitle={preTitle}
              headingType="h2"
              align="left"
              size={`5xl`}
            />
          )}
          {renderDocumentToReactComponents(rightColumn)}
        </div>
      </div>
    </Container>
  )
}

export default CardHighlightContentBlockDefault
