import { useMemo } from 'react'
import { TypeVideo } from '../../types/contentful'
import PodcastBlock from './PodcastBlock'
import VideoBlockDefault from './VideoBlockDefault'

type VideoBlockProps = {
  block: TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const VideoBlock = ({ block }: VideoBlockProps) => {
  const { variant } = block.fields
  const renderVariant = useMemo(() => {
    switch (variant) {
      case 'Podcast':
        return <PodcastBlock block={block} />
      case 'Video':
      default:
        return <VideoBlockDefault block={block} />
    }
  }, [variant, block])

  return renderVariant
}
export default VideoBlock
