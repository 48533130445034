import { isContentfulType } from '../../lib/contentful'
import {
  TypeArticle,
  TypeArticleSkeleton,
  TypeButton,
  TypeButtonSkeleton,
  TypeCourseSkeleton,
  TypeEventSkeleton,
  TypeMarketoFormButtonBlock,
  TypeMarketoFormButtonBlockSkeleton,
  TypePerson,
  TypePersonSkeleton,
} from '../../types/contentful'
import React from 'react'
import { CardShape, CardSize } from '../../types/content'
import { ArticleCard } from './ArticleCard'
import { CourseCard } from './CourseCard'
import { EventCard } from './EventCard'
import { PersonCard } from './PersonCard'
import { ICourseExtended, IEventExtended } from '../../types/types'
import { Entry } from 'contentful'

type RenderCardProps = {
  shape?: CardShape
  size?: CardSize
  item:
    | TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | ICourseExtended
    | IEventExtended
    | Entry<
        TypeArticleSkeleton | TypePersonSkeleton | TypeCourseSkeleton | TypeEventSkeleton,
        'WITHOUT_UNRESOLVABLE_LINKS',
        string
      >
  type?: 'Default' | 'Description' | 'Promotion'
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  onCardClick?: () => void
}

export const RenderCard = ({ item, onCardClick, size, shape, type, button }: RenderCardProps) => {
  const cardSize = size || item?.fields?.size || 'Small Square'
  const cardShape = shape || item?.fields?.shape || undefined
  const cornersStyle = 'Square'

  if (isContentfulType<TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>>(item, 'article')) {
    return (
      <ArticleCard
        article={item}
        size={cardSize}
        shape={cardShape}
        type={type}
        cornersStyle={cornersStyle}
        button={button}
      />
    )
  }
  if (isContentfulType<ICourseExtended>(item, 'course')) {
    return (
      <CourseCard
        course={item}
        onCardClick={onCardClick}
        size={cardSize}
        shape={cardShape}
        layoutType={type}
        cornersStyle={cornersStyle}
        button={button}
      />
    )
  }
  if (isContentfulType<IEventExtended>(item, 'event')) {
    return (
      <EventCard
        event={item}
        onCardClick={onCardClick}
        size={cardSize}
        shape={cardShape}
        type={type}
        cornersStyle={cornersStyle}
        button={button}
      />
    )
  }
  if (isContentfulType<TypePerson<'WITHOUT_UNRESOLVABLE_LINKS', string>>(item, 'person')) {
    return (
      <PersonCard
        person={item}
        size={cardSize}
        shape={cardShape}
        type={type}
        cornersStyle={cornersStyle}
        button={button}
      />
    )
  }
  return null
}
