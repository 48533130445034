import { isContentfulType } from '../../lib/contentful'
import { TypeButton, TypeMarketoFormButtonBlock } from '../../types/contentful'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import React from 'react'

type CTAButtonProps = {
  buttons: (
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  )[]
  onClick?: () => void
  slug?: string
}

export const renderCTAButtons = ({ buttons, onClick, slug }: CTAButtonProps) => {
  if (!buttons) return
  return buttons?.map(button => {
    if (
      isContentfulType<TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        button,
        'marketoFormButtonBlock'
      )
    ) {
      return <></>
    } else if (
      isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(button, 'button')
    ) {
      return (
        <Button
          id={button?.sys?.id}
          key={button.sys.id}
          className="justify-center flex-shrink-0"
          buttonStyle={contentfulType[button?.fields?.style]}
          size={contentfulSize[button?.fields?.size]}
          href={button?.fields?.url?.trim() === '' ? slug : button?.fields?.url}
          urlBehaviour={button?.fields?.urlBehaviour}
          variant={contentfulVariant[button?.fields.variant]}
          onClick={onClick}
        >
          {button.fields.icon && <Icon className="mr-1 w-4 h-4" name={button.fields.icon} />}
          {button?.fields?.label}
        </Button>
      )
    }
  })
}
