import React from 'react'

interface CardTagProp {
  label: string
  isPrimeTag?: boolean
  type?: 'Default' | 'Description' | 'Promotion'
  tagStyle?: string
  backgroundColor?: string
  textColor?: string
}

const CardTag = ({
  label,
  isPrimeTag = false,
  tagStyle,
  backgroundColor = 'transparent',
  textColor,
}: CardTagProp) => {
  const styles =
    tagStyle +
    (isPrimeTag
      ? ' bg-primary-ui-black text-primary-ui-white text-sm px-3 py-0.5 mr-2 mb-2 transition-all duration-300'
      : ' text-sm px-3 py-0.5 mr-2 mb-2 transition-all duration-300')

  return (
    <span
      style={{
        background: !isPrimeTag ? backgroundColor : 'black',
        color: !isPrimeTag ? textColor : 'white',
        border: backgroundColor === 'transparent' ? '1px solid black' : '1px solid transparent',
      }}
      className={`${styles}`}
    >
      {label}
    </span>
  )
}

export default CardTag
