import React, { useRef } from 'react'
import { H1, PreTitle, SubTitle } from '../Typography/Typography'
import { Icon } from '../Icon/Icon'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperCore from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import {
  TypeButton,
  TypeHeroSlider,
  TypeImageWithFocalPoint,
  TypeMarketoFormButtonBlock,
  TypeVideo,
} from '../../types/contentful'
import isDarkColor from '../../helpers/isDarkColor'
import { isContentfulType } from '../../lib/contentful'
import VideoSlide from './VideoSlide'
import ImageSlide from './ImageSlide'
import { buildSwiperDefaultProps } from '../slider.constants'
import { renderCTAButtons } from '../CTAButtons/CTAButtons'
import Container from '../Container/Container'
import { MediaType } from '../../types/types'

const HeroSlider = ({
  preTitle,
  title,
  subTitle,
  buttons,
  slides,
  backgroundColour,
  iconColour,
}: TypeHeroSlider<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']) => {
  const swiperRef = useRef<SwiperCore>()
  const slideStyle = `relative w-full h-[20rem] md:h-[30rem] lg:h-[40rem] ${
    slides?.length > 1 ? 'mb-14' : ''
  }`

  return (
    <Container className={backgroundColour && isDarkColor(backgroundColour) ? 'dark' : ''}>
      <div
        className={`full-width text-primary-ui-black dark:text-primary-ui-white overflow-clip ${
          backgroundColour && isDarkColor(backgroundColour) ? 'dark' : 'bg-primary-brand'
        }`}
        style={{ backgroundColor: backgroundColour || 'transparent' }}
      >
        <div
          className={`absolute -right-2 top-20 md:-top-2 left-10 md:left-1/2 bottom-0 overflow-clip ${
            iconColour ? '' : 'text-accent-6-600'
          }`}
          style={{ color: iconColour }}
        >
          <Icon name="AcademyEXLogo" />
        </div>
        <div className="container mx-auto relative">
          <div className="flex items-center flex-col-reverse md:flex-row md:justify-end">
            <div className="md:absolute w-full left-0 md:left-8 z-40 mb-12 md:mb-0 md:w-1/3">
              {preTitle && (
                <div className={'md:w-2/3'}>
                  <PreTitle>{preTitle}</PreTitle>
                </div>
              )}
              <H1 className="font-primary text-4xl lg:text-8xl xl:text-9xl mb-8">{title}</H1>
              {subTitle && (
                <div className={'w-full lg:w-2/3'}>
                  <SubTitle>{subTitle}</SubTitle>
                </div>
              )}
              {buttons && buttons.length > 0 && (
                <div className="flex flex-col items-start gap-4 my-8">
                  {renderCTAButtons({ buttons } as {
                    buttons: (
                      | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
                      | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
                    )[]
                  })}
                </div>
              )}
            </div>
            <div className="slider top-0 w-full md:w-[62%] lg:w-[72%] xl:w-[80%] my-20 pt-5 lg:pt-6">
              <Swiper
                {...buildSwiperDefaultProps({ enabled: slides?.length > 1 })}
                onBeforeInit={swiper => {
                  swiperRef.current = swiper
                }}
                key={`swiper-hero-slider-${title}`}
              >
                {slides &&
                  slides
                    ?.filter(
                      slide =>
                        (isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                          slide.fields.content,
                          'video'
                        ) &&
                          slide.fields.content.fields.variant === MediaType.VIDEO) ||
                        isContentfulType<
                          TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
                        >(slide.fields.content, 'imageWithFocalPoint')
                    )
                    ?.map((slide, index) => {
                      if (
                        isContentfulType<
                          TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
                        >(slide.fields.content, 'imageWithFocalPoint')
                      ) {
                        return (
                          <SwiperSlide key={index} virtualIndex={index}>
                            <ImageSlide
                              className={slideStyle}
                              block={slide.fields.content}
                              index={index}
                              shape={slide.fields.shape}
                            />
                          </SwiperSlide>
                        )
                      }

                      if (
                        isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                          slide.fields.content,
                          'video'
                        )
                      ) {
                        return (
                          <SwiperSlide key={index}>
                            <VideoSlide
                              className={slideStyle}
                              block={slide.fields.content}
                              shape={slide.fields.shape}
                            />
                          </SwiperSlide>
                        )
                      }
                    })}
                {slides?.length > 1 && (
                  <>
                    <Icon
                      name="ArrowLeft"
                      className="cursor-pointer absolute bottom-0 left-1/2 -translate-x-14 z-50"
                      onClick={() => swiperRef.current?.slidePrev()}
                    />
                    <Icon
                      name="ArrowRight"
                      className="cursor-pointer absolute bottom-0 left-1/2 translate-x-10 z-50"
                      onClick={() => swiperRef.current?.slideNext()}
                    />
                  </>
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default HeroSlider
