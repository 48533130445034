import { ProductList } from './product-list.model'

//Courses
export const BROWSE_COURSES_LIST: ProductList = {
  item_list_id: 'courses',
  item_list_name: 'Browse Courses',
}
export const HOME_COURSES_LIST: ProductList = {
  item_list_id: 'home_courses',
  item_list_name: 'Homepage Courses',
}
