// Render Article
import { DEFAULT_STORY_CATEGORY } from '../../lib/articles'
import { getImageWithFocalPoint } from '../contentful-ui-utils'
import React, { useEffect, useState } from 'react'
import Card from './Card'
import { CardShape, CardSize } from '../../types/content'
import { ArticleType } from '../../types/types'
import { TypeArticle, TypeButton, TypeMarketoFormButtonBlock } from '../../types/contentful'

type ArticleCardProps = {
  article: TypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', string>
  shape?: CardShape
  size?: CardSize
  cornersStyle?: 'Rounded' | 'Square'
  button?:
    | TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>
    | TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
  type?: 'Default' | 'Description' | 'Promotion'
}

export const ArticleCard = ({
  article,
  size,
  shape,
  cornersStyle,
  button,
  type,
}: ArticleCardProps) => {
  const [hydratedCreatedAt, setHydratedCreatedAt] = useState('')
  const { title, metaDescription, slug, readTime, author, hero, tags, category } = article.fields
  const { createdAt } = article.sys
  const categorySlug = category?.fields?.slug || DEFAULT_STORY_CATEGORY

  const typeMapping = {
    [ArticleType.ARTICLE]: `/stories/${categorySlug}/${slug}`,
    [ArticleType.CASE_STUDY]: `/case-studies/${categorySlug}/${slug}`,
    [ArticleType.BLOG]: `/blogs/${categorySlug}/${slug}`,
  }

  const articleSlug = typeMapping[article.fields.type] || `/stories/${categorySlug}/${slug}`

  useEffect(() => {
    setHydratedCreatedAt(createdAt)
  }, [createdAt])

  return (
    <Card
      title={title}
      description={metaDescription}
      slug={articleSlug}
      readTime={readTime}
      author={author}
      createdAt={hydratedCreatedAt}
      size={size || article.fields.size}
      thumbnailWithFocalPoint={getImageWithFocalPoint(hero)}
      cardType="Article"
      tags={tags}
      shape={shape}
      type={type}
      cornersStyle={cornersStyle}
      button={button}
      isCaseStudy={article.fields.type === ArticleType.CASE_STUDY}
      {...article}
    />
  )
}
