import { EffectFade, Pagination } from 'swiper'
import { SwiperProps } from 'swiper/react'
import { PaginationOptions } from 'swiper/types/modules/pagination'

export const buildSwiperDefaultProps = ({ ...rest }: PaginationOptions): SwiperProps => {
  return {
    modules: [EffectFade, Pagination],
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 500,
    pagination: {
      clickable: true,
      horizontalClass:
        'absolute flex justify-center z-40 bottom-1 left-1/2 transform -translate-x-1/2',
      bulletElement: 'div',
      bulletClass:
        'bg-primary-ui-900 dark:bg-primary-ui-white opacity-50 w-2.5 h-2.5 rounded-full transition-all duration-300 ease-in-out mr-2.5 cursor-pointer',
      bulletActiveClass: 'w-10 !opacity-100',
      ...rest,
    },
    spaceBetween: 50,
    slidesPerView: 1,
  }
}
