import React, { useMemo } from 'react'
import { TypeCardHighlightContentBlock } from '../../types/contentful'

import CardHighlightContentBlockV2 from './CardHighlightContentBlockV2'
import CardHighlightContentBlockDefault from './CardHighlightContentBlockDefault'
import CardHighlightContentBlockInner from './CardHighlightContentBlockInner'

type CardHighlightContentBlockProps = {
  entry: TypeCardHighlightContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const CardHighlightContentBlock = ({ entry }: CardHighlightContentBlockProps) => {
  const { variant } = entry.fields

  const renderVariant = useMemo(() => {
    switch (variant) {
      case 'Inner':
        return <CardHighlightContentBlockInner entry={entry} />
      case 'v2':
        return <CardHighlightContentBlockV2 entry={entry} />
      case 'Default':
      default:
        return <CardHighlightContentBlockDefault entry={entry} />
    }
  }, [variant, entry])

  return renderVariant
}

export default CardHighlightContentBlock
