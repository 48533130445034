import React from 'react'
import { TypeCardHighlightContentBlock } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'

type CardHighlightContentBlockProps = {
  entry: TypeCardHighlightContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const CardHighlightContentBlockInner = ({ entry }: CardHighlightContentBlockProps) => {
  const { leftColumn, rightColumn } = entry.fields
  const styles = 'break-words text-center md:text-left font-extralight leading-4 pb-4 not-italic'

  return (
    <>
      <address className={styles}>{renderDocumentToReactComponents(leftColumn)}</address>
      <address className={styles}>{renderDocumentToReactComponents(rightColumn)}</address>
    </>
  )
}

export default CardHighlightContentBlockInner
