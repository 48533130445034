import {
  TypeButton,
  TypeButtonFields,
  TypeImageGrid,
  TypeMarketoFormButtonBlock,
} from '../../types/contentful'
import TitleBlock from '../TitleBlock/TitleBlock'
import getFocalRatio from '../../helpers/getFocalRatio'
import Image from 'next/legacy/image'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { H3 } from '../Typography/Typography'
import ReactMarkdown from 'react-markdown'
import { isContentfulType } from '../../lib/contentful'
import MarketoFormButtonBlock from '../MarketoFormButtonBlock/MarketoFormButtonBlock'
import { useMemo } from 'react'
import Link from 'next/link'
import { Icon } from '../Icon/Icon'
import isDarkColor from '../../helpers/isDarkColor'
import Container from '../Container/Container'

type ImageGridProps = {
  entry: TypeImageGrid<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const LinkWrapper = ({ url, children }: { url: string; children: React.ReactNode }) => {
  return url ? (
    <Link href={url} legacyBehavior>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

const ImageGrid = ({ entry }: ImageGridProps) => {
  const { mobileBehavior, ctaButtons } = entry.fields

  const gridFlow = useMemo(
    () =>
      mobileBehavior === 'Vertical'
        ? 'grid-flow-row md:grid-flow-col p-10'
        : 'grid-flow-col pl-[10%]',
    [mobileBehavior]
  )

  const backgroundStyle = useMemo(() => {
    switch (entry?.fields?.backgroundShape) {
      case 'Bolt':
        return 'bolt-wide'
      case 'Bubble':
        return 'bubble-wide'
      case 'Cube':
        return 'cube-wide'
      case 'Tab':
        return 'tab-wide'
      default:
        return ''
    }
  }, [entry?.fields?.backgroundShape])

  const hasImages = entry?.fields?.imageGridItems
  return (
    <Container
      className={`${!hasImages && 'inline-block'} full-width pb-1`}
      style={
        entry?.fields?.backgroundShape ? {} : { backgroundColor: entry?.fields?.backgroundColour }
      }
      id={entry.sys.id}
    >
      <div
        className={`relative mb-3 md:mb-6 lg:mb-12 py-10 container mx-auto ${
          hasImages || ctaButtons ? '' : 'pb-6'
        }`}
      >
        {entry?.fields?.backgroundShape && (
          <div
            className={`absolute top-0 left-0 -bottom-10 right-0 -z-10 pb-[10rem] ${backgroundStyle}`}
            style={
              entry?.fields?.backgroundShape
                ? { backgroundColor: entry?.fields?.backgroundColour }
                : {}
            }
          />
        )}

        <TitleBlock
          title={entry?.fields?.title}
          preTitle={entry?.fields?.preTitle}
          headingType={'h2'}
          size={'5xl'}
          description={entry?.fields?.description}
          textColor={entry?.fields?.textColor}
          descriptionWidth={entry?.fields?.descriptionWidth}
          contenfulId={entry.sys.id}
        />

        {entry?.fields?.imageGridItems && (
          <div
            className={`full-width sm:constrained-width grid ${gridFlow} md:p-0 auto-cols-[minmax(80%,_1fr)] ${
              entry.fields.imageGridItems.length === 2
                ? entry?.fields?.columnWidth === 'Narrow'
                  ? 'md:auto-cols-[18%]'
                  : 'md:auto-cols-[30%]'
                : 'md:auto-cols-[23%]'
            } gap-4 sm:gap-4 mt-4 sm:max-w-full overflow-x-scroll md:justify-center`}
            style={entry?.fields?.textColor ? { color: entry?.fields?.textColor } : {}}
          >
            {entry?.fields?.imageGridItems?.map((item, index) => {
              const focalRatio = getFocalRatio(item?.fields?.image?.fields)

              return (
                <LinkWrapper
                  url={
                    (item?.fields?.ctaButton as TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>)
                      ?.fields?.url
                  }
                  key={index}
                >
                  <div
                    className={`flex flex-col items-center mb-2 ${
                      (item?.fields?.ctaButton as TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>)
                        ?.fields?.url
                        ? 'cursor-pointer'
                        : ''
                    }`}
                  >
                    {item?.fields?.image?.fields?.image?.fields?.file?.url &&
                    entry?.fields?.imageSize === 'Fit' ? (
                      <div
                        className={`relative w-full mb-4 flex items-center justify-center scroll-m-[20rem]`}
                        id={item?.fields?.image?.sys?.id}
                      >
                        <Image
                          src={`https:${item?.fields?.image?.fields?.image?.fields?.file?.url}`}
                          alt={
                            item?.fields?.image?.fields?.caption ||
                            item?.fields?.image?.fields?.title
                          }
                          width={`${
                            mobileBehavior === 'Vertical'
                              ? 150
                              : item?.fields?.image?.fields?.image?.fields?.file?.details?.image
                                  ?.width
                          }`}
                          height={`${
                            mobileBehavior === 'Vertical'
                              ? 50
                              : item?.fields?.image?.fields?.image?.fields?.file?.details?.image
                                  ?.height
                          }`}
                        />
                      </div>
                    ) : item?.fields?.image?.fields?.image?.fields?.file?.url ? (
                      <div
                        className={`relative w-full mb-4 aspect-[1/1] scroll-m-[20rem] ${
                          entry.fields.imageSize === 'Tall' ? 'md:aspect-[1/2]' : ''
                        }`}
                        id={item?.fields?.image?.sys?.id}
                      >
                        <Image
                          src={`https:${item?.fields?.image?.fields?.image?.fields?.file?.url}`}
                          alt={
                            item?.fields?.image?.fields?.caption ||
                            item?.fields?.image?.fields?.title
                          }
                          layout="fill"
                          objectFit="cover"
                          objectPosition={`${focalRatio?.x}% ${focalRatio?.y}%`}
                          sizes={`20rem`}
                        />
                      </div>
                    ) : null}

                    {item?.fields?.title && (
                      <H3 size={'3xl'} align={'center'}>
                        {item?.fields?.title}
                      </H3>
                    )}
                    {item?.fields?.description && (
                      <ReactMarkdown className={`mb-5 text-center text-secondary-ui-700`}>
                        {item?.fields?.description}
                      </ReactMarkdown>
                    )}
                    {isContentfulType<
                      TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
                    >(item?.fields?.ctaButton, 'marketoFormButtonBlock') && (
                      <MarketoFormButtonBlock entry={item?.fields?.ctaButton} />
                    )}
                    {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                      item?.fields?.ctaButton,
                      'button'
                    ) &&
                      !!item?.fields?.ctaButton?.fields?.url && (
                        <div className="mt-auto mb-1">
                          <Button
                            id={item?.fields?.ctaButton?.sys?.id}
                            href={item?.fields?.ctaButton?.fields?.url}
                            buttonStyle={contentfulType[item?.fields?.ctaButton?.fields?.style]}
                            size={contentfulSize[item?.fields?.ctaButton?.fields?.size]}
                            variant={contentfulVariant[item?.fields?.ctaButton?.fields?.variant]}
                          >
                            {item?.fields?.ctaButton?.fields?.label}
                          </Button>
                        </div>
                      )}
                  </div>
                </LinkWrapper>
              )
            })}
          </div>
        )}

        <div className="flex flex-col md:flex-row justify-center gap-3 items-center md:items-stretch">
          {ctaButtons &&
            ctaButtons.length >= 1 &&
            ctaButtons?.map((button, index) => {
              if (
                isContentfulType<TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  button,
                  'marketoFormButtonBlock'
                )
              ) {
                return <MarketoFormButtonBlock entry={button} key={index} />
              } else if (
                isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  button,
                  'button'
                ) &&
                button?.fields?.url
              ) {
                return (
                  <Button
                    id={button?.sys?.id}
                    href={button?.fields?.url}
                    buttonStyle={contentfulType[button?.fields?.style]}
                    size={contentfulSize[button?.fields?.size]}
                    variant={contentfulVariant[button?.fields?.variant]}
                    urlBehaviour={button?.fields?.urlBehaviour}
                    hoverColor={button?.fields?.hoverColor}
                    backgroundColor={button?.fields?.color}
                    key={index}
                    textColor={
                      button?.fields?.hoverColor &&
                      (isDarkColor(button?.fields?.hoverColor)
                        ? 'text-primary-ui-white'
                        : 'text-primary-ui-black')
                    }
                  >
                    <Icon className="w-5 h-5 mr-2" name={button.fields.icon} />
                    {button?.fields?.label}
                  </Button>
                )
              }
            })}
        </div>
      </div>
    </Container>
  )
}

export default ImageGrid
