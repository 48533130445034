import {
  TypeFeaturedContentBlock,
  TypeListItem,
  TypeQuoteContentBlock,
} from '../../types/contentful'
import React, { useRef, useMemo } from 'react'
import { Icon } from '../Icon/Icon'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperCore from 'swiper'

import QuoteCard from '../QuoteContentBlock/QuoteCard'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import isDarkColor from '../../helpers/isDarkColor'
import TitleBlock from '../TitleBlock/TitleBlock'
import { buildSwiperDefaultProps } from '../slider.constants'
import { isContentfulType } from '../../lib/contentful'
import MiddleAlignedListItem from '../ListContentBlock/MiddleAlignedListItem'
import Container from '../Container/Container'

const FeaturedContentBlockQuotes = ({
  preTitle,
  title,
  featuredContentBlockItems,
  colour,
  backgroundShape,
  align,
}: TypeFeaturedContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']) => {
  const swiperRef = useRef<SwiperCore>()
  const isDark = colour && isDarkColor(colour)
  const containerClasses = `full-width py-3 md:py-6 lg:py-12 ${isDark ? 'text-white dark' : ''} `
  // generate unique id with a number, add useMemo so it's always the same

  const isIListItem = useMemo(() => {
    return featuredContentBlockItems?.some(item => {
      return isContentfulType(item, 'listItem')
    })
  }, [featuredContentBlockItems])

  return (
    <Container
      className={containerClasses}
      style={backgroundShape ? {} : { backgroundColor: colour }}
      id={`id-${title}`}
    >
      <div className="container mx-auto">
        <div
          className={`absolute h-[88%] w-full container mx-auto top-28 left-0 bottom-16 sm:bottom-6 right-0 -z-10 `}
          style={backgroundShape ? { backgroundColor: colour } : {}}
        />
        <div className="flex items-end justify-between mb-9 sm:mb-12 mt-9 md:mt-0">
          <div className={`-mb-8 w-full`}>
            <TitleBlock
              title={title}
              preTitle={preTitle}
              headingType={`h2`}
              size={`5xl`}
              align={align?.toLowerCase()}
            />
          </div>
        </div>

        <div>
          <Swiper
            {...buildSwiperDefaultProps({
              // enabled: featuredContentBlockItems?.length > 4 disabled for now, enable calculation is not working properly on the library
              horizontalClass:
                'absolute flex justify-center z-40 !top-1 left-1/2 transform -translate-x-1/2 !h-fit',
            })}
            spaceBetween={5}
            breakpoints={{
              512: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                pagination: {
                  enabled: featuredContentBlockItems?.length > 1,
                },
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
                pagination: {
                  enabled: featuredContentBlockItems?.length > 2,
                },
              },
              768: {
                slidesPerView: isIListItem ? 2 : 3,
                slidesPerGroup: isIListItem ? 2 : 3,
                spaceBetween: isIListItem ? 40 : 4,
                pagination: {
                  enabled: featuredContentBlockItems?.length > 3,
                },
              },
              1200: {
                slidesPerView: isIListItem ? 3 : 4,
                slidesPerGroup: isIListItem ? 3 : 4,
                spaceBetween: isIListItem ? 50 : 4,

                pagination: {
                  enabled: featuredContentBlockItems?.length > 4,
                },
              },
            }}
            effect={'slide'}
            loop={false}
            onBeforeInit={swiper => {
              swiperRef.current = swiper
            }}
            key={`swiper-featured-content-block-${title}`}
            className="!pt-10"
          >
            {featuredContentBlockItems?.map((item, index) => {
              return (
                <SwiperSlide key={index} className="mb-20">
                  {isContentfulType<TypeQuoteContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                    item,
                    'quoteContentBlock'
                  ) && <QuoteCard entry={item} />}
                  {isContentfulType<TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                    item,
                    'listItem'
                  ) && (
                    <MiddleAlignedListItem
                      listItem={item}
                      index={index}
                      key={`${item.sys.id}-slider-${index}`}
                    />
                  )}
                </SwiperSlide>
              )
            })}
            {featuredContentBlockItems?.length > 4 && (
              <>
                <Icon
                  name="ArrowLeft"
                  className="bg-white cursor-pointer absolute top-0 left-1/2 -translate-x-20 md:-translate-x-14 z-50"
                  onClick={() => swiperRef.current?.slidePrev()}
                />
                <Icon
                  name="ArrowRight"
                  className="cursor-pointer absolute top-0 left-1/2 translate-x-16 md:translate-x-10 z-50"
                  onClick={() => swiperRef.current?.slideNext()}
                />
              </>
            )}
          </Swiper>
        </div>
      </div>
    </Container>
  )
}

export default FeaturedContentBlockQuotes
