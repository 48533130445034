import React from 'react'
import { TypeCardContentBlock } from '../../types/contentful'
import { RenderCard } from '../Card/RenderCard'
import { mergeItemFields } from '../../helpers/mergeItemFields'
import { useContentfulResources } from '../ContentfulDocument/ContentfulResourcesProvider'

type CardContentBlockProps = {
  entry: TypeCardContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const CardContentBlock = ({ entry }: CardContentBlockProps) => {
  const { events, courses } = useContentfulResources()
  const item = mergeItemFields(entry.fields.item, courses, events)
  return <RenderCard item={item} size="Medium Wide" shape={entry.fields.shape || 'Cube'} />
}

export default CardContentBlock
