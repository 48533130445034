import React from 'react'
import { isContentfulType } from '../../lib/contentful'
import { Entry } from 'contentful'
import {
  TypeAccordion,
  TypeButton,
  TypeCardContentBlock,
  TypeCardHighlightContentBlock,
  TypeFeaturedContentBlock,
  TypeHeroSlider,
  TypeImageWithFocalPoint,
  TypeMarketoFormButtonBlock,
  TypeVideo,
  TypeQuoteContentBlock,
  TypeGridContentBlock,
  TypeGridIconItemContentBlock,
  TypeListContentBlock,
  TypeImageGrid,
  TypeLogoBannerBlock,
  TypeHomeCoursesBlock,
  TypeHeroBlock,
  TypeListItem,
  TypeTextBlock,
} from '../../types/contentful'
import HeroSlider from '../HeroSlider/HeroSlider'
import FeaturedContentBlock from '../FeaturedContentBlock/FeaturedContentBlock'
import CardHighlightContentBlock from '../CardHighlightContentBlock/CardHighlightContentBlock'
import QuoteContentBlock from '../QuoteContentBlock/QuoteContentBlock'
import CardContentBlock from '../CardContentBlock/CardContentBlock'
import ImageWithFocalPointBlock from '../ImageWithFocalPointBlock/ImageWithFocalPointBlock'
import VideoBlock from '../VideoBlock/VideoBlock'
import AccordionBlock from '../AccordionBlock/AccordionBlock'
import ListContentBlock from '../ListContentBlock/ListContentBlock'
import GridContentBlock from '../GridContentBlock/GridContentBlock'
import GridIconItemContentBlock from '../GridContentBlock/GridIconItemContentBlock'
import ImageGrid from '../ImageGrid/ImageGrid'
import LogoBannerBlock from '../LogoBannerBlock/LogoBannerBlock'
import HomePageCourses from '../HomePageCourses/HomePageCourses'
import HeroBlock from '../HeroBlock/HeroBlock'
import ButtonBlock from '../ButtonBlock/ButtonBlock'
import MarketoFormButtonBlock from '../MarketoFormButtonBlock/MarketoFormButtonBlock'
import MiddleAlignedListItem from '../ListContentBlock/MiddleAlignedListItem'
import TextBlock from '../TextBlock/TextBlock'

type EmbeddedEntryProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: Entry<any>
}

export const EmbeddedEntry = ({ entry }: EmbeddedEntryProps): JSX.Element | null => {
  if (isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'button')) {
    return <ButtonBlock entry={entry} />
  }

  if (isContentfulType<TypeHeroSlider<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'heroSlider')) {
    return <HeroSlider {...entry.fields} />
  }

  if (
    isContentfulType<TypeFeaturedContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'featuredContentBlock'
    )
  ) {
    return <FeaturedContentBlock entry={entry.fields} />
  }

  if (
    isContentfulType<TypeCardHighlightContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'cardHighlightContentBlock'
    )
  ) {
    return <CardHighlightContentBlock entry={entry} />
  }

  if (
    isContentfulType<TypeQuoteContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'quoteContentBlock'
    )
  ) {
    return <QuoteContentBlock entry={entry} />
  }

  if (
    isContentfulType<TypeGridContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'gridContentBlock'
    )
  ) {
    return <GridContentBlock entry={entry} />
  }

  if (
    isContentfulType<TypeGridIconItemContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'gridIconItemContentBlock'
    )
  ) {
    return <GridIconItemContentBlock entry={entry} />
  }

  if (
    isContentfulType<TypeCardContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'cardContentBlock'
    )
  ) {
    return <CardContentBlock entry={entry} />
  }

  if (
    isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'imageWithFocalPoint'
    )
  ) {
    return <ImageWithFocalPointBlock entry={entry} />
  }

  if (isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'video')) {
    return <VideoBlock block={entry} />
  }

  if (isContentfulType<TypeAccordion<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'accordion')) {
    return <AccordionBlock block={entry} />
  }

  if (
    isContentfulType<TypeListContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'listContentBlock'
    )
  ) {
    return <ListContentBlock entry={entry} />
  }

  if (isContentfulType<TypeImageGrid<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'imageGrid')) {
    return <ImageGrid entry={entry} />
  }

  if (
    isContentfulType<TypeLogoBannerBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'logoBannerBlock'
    )
  ) {
    return <LogoBannerBlock entry={entry} />
  }

  if (
    isContentfulType<TypeHomeCoursesBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'homeCoursesBlock'
    )
  ) {
    return <HomePageCourses entry={entry} />
  }

  if (isContentfulType<TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'heroBlock')) {
    return <HeroBlock entry={entry} />
  }

  if (
    isContentfulType<TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
      entry,
      'marketoFormButtonBlock'
    )
  ) {
    return <MarketoFormButtonBlock entry={entry} />
  }

  if (isContentfulType<TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'listItem')) {
    return <MiddleAlignedListItem listItem={entry} index={entry.sys.id} />
  }

  if (isContentfulType<TypeTextBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(entry, 'textBlock')) {
    return <TextBlock entry={entry} />
  }

  // eslint-disable-next-line no-console
  console.error(`Type "${entry?.sys?.contentType?.sys?.id}" not implemented.`)

  return null
}
