import React, { ReactNode } from 'react'
import { TypeListContentBlock, TypeListItem } from '../../types/contentful'
import { Icon } from '../Icon/Icon'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import TitleBlock from '../TitleBlock/TitleBlock'
import Link from 'next/link'
import VerticalListBlock from '../ListContentBlock/VerticalListBlock'
import MiddleAlignedListItem from './MiddleAlignedListItem'
import Container from '../Container/Container'

type ListContentBlockProps = {
  entry: TypeListContentBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const ListContentBlock = ({ entry }: ListContentBlockProps) => {
  const { preTitle, title, description, listItems, listType, lineColor } = entry.fields
  const iconContainerClass = `relative ${listType === 'Left Aligned' ? 'mt-5 text-left' : '-mt-6'}`

  // Declare all valid column classes to tailwind
  const columnClass = ['lg:grid-cols-1', 'lg:grid-cols-2', 'lg:grid-cols-3', 'lg:grid-cols-4'][
    listItems.length - 1
  ]

  const getListItemElement = (
    listItem: TypeListItem<'WITHOUT_UNRESOLVABLE_LINKS', string>,
    index: number
  ) => {
    let iconWrapperClass = `inline-flex items-center justify-center `
    let renderItem: ReactNode
    switch (listType) {
      case 'Connected':
        iconWrapperClass +=
          `rounded-full p-4 shadow-lg after:content-[''] before:content-[''] after:absolute after:bg-primary-brand before:absolute before:bg-primary-brand` +
          (index > 0
            ? ` lg:before:right-[calc(50%+24px)] lg:before:-left-[50%] lg:before:h-[2px]`
            : '') +
          (index < listItems.length - 1
            ? ' after:left-[32px] sm:after:left-[43px] md:after:left-[54px] after:w-[2px] after:top-[64px] after:bottom-0 after:h-full ' +
              'lg:after:-right-[20%] lg:after:left-[calc(50%+24px)] lg:after:h-[2px] lg:after:top-[31px] lg:after:w-auto'
            : '')
        renderItem = (
          <div key={`${listItem.sys.id}-${index}`} className="pt-6">
            <div
              className="relative flow-root rounded-lg px-6 pb-8"
              style={{
                backgroundColor: 'transparent',
              }}
            >
              <div className="absolute rounded-lg left-0 top-0 right-0 bottom-0 z-0 bg-primary-ui-white opacity-90" />
              <div className={iconContainerClass + ' z-10 flex lg:block'}>
                <div className={'w-1/5 inline-block h-full lg:w-full md:block md:h-auto'}>
                  <div
                    className={'lineColor ' + iconWrapperClass}
                    style={
                      {
                        '--svg-color': `${
                          listItem.fields.iconColor
                            ? listItem.fields.iconColor
                            : listItem.fields?.icon === 'LightningSolid'
                            ? 'white'
                            : 'black'
                        }`,
                        backgroundColor: listItem.fields.colour,
                        ...(lineColor ? { '--data-attribute-line-color': lineColor } : {}),
                      } as React.CSSProperties
                    }
                  >
                    <Icon
                      className={`h-8 w-8 text-[--svg-color]`}
                      aria-hidden="true"
                      name={listItem.fields.icon}
                    />
                  </div>
                </div>
                <div
                  className={
                    'ml-8 sm:ml-0 w-4/5 inline-block md:w-full md:block text-left lg:text-center'
                  }
                >
                  <h3 className="lg:mt-8 text-lg font-medium tracking-tight mb-5">
                    {listItem.fields.title}
                  </h3>
                  <div>{renderDocumentToReactComponents(listItem.fields.content)}</div>
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 'Middle Aligned': {
        renderItem = (
          <MiddleAlignedListItem
            listItem={listItem}
            index={index}
            key={`${listItem.sys.id}-${index}`}
          />
        )
        break
      }
      case 'Vertical':
        renderItem = <VerticalListBlock listItem={listItem} key={`${listItem.sys.id}-${index}`} />
        break
      default:
        iconWrapperClass += 'rounded-full p-3 shadow-lg'
        renderItem = (
          <div className="pt-6" key={`${listItem.sys.id}-${index}`}>
            <div
              className="relative flow-root rounded-lg px-6 pb-8"
              style={{
                backgroundColor: listItem.fields.colour,
              }}
            >
              <div className="absolute rounded-lg left-0 top-0 right-0 bottom-0 z-0 bg-primary-ui-white opacity-90" />
              <div className={iconContainerClass}>
                <div>
                  <span
                    className={iconWrapperClass}
                    style={{
                      backgroundColor: listItem.fields.colour,
                    }}
                  >
                    <Icon
                      className="h-6 w-6 text-primary-ui-black"
                      aria-hidden="true"
                      name={listItem.fields.icon}
                    />

                    {listItem?.fields?.link && (
                      <Icon className={'h-6 w-6 absolute right-0 top-0'} name={'ArrowUpRight'} />
                    )}
                  </span>
                </div>
                <h3 className="mt-8 text-lg font-medium tracking-tight mb-5">
                  {listItem.fields.title}
                </h3>
                <div>{renderDocumentToReactComponents(listItem.fields.content)}</div>
              </div>
            </div>
          </div>
        )
        break
    }

    if (listType === 'Connected') {
      return renderItem
    }

    return listItem?.fields?.link ? (
      <Link key={`${listItem.sys.id}-${index}`} href={listItem.fields.link} legacyBehavior>
        <div
          className={`relative cursor-pointer`}
          // Fix to allow links to be clicked
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if ((e.target as HTMLAnchorElement).href) {
              e.preventDefault()
              window.location.href = (e.target as HTMLAnchorElement).href
              e.stopPropagation()
            }
          }}
        >
          {renderItem}
        </div>
      </Link>
    ) : (
      renderItem
    )
  }

  return (
    <Container className="text-center py-3 md:py-6 lg:py-12">
      <TitleBlock title={title} preTitle={preTitle} headingType={`h2`} size={`5xl`}>
        {description && (
          <p className={`mt-8 text-secondary-ui-500 text-lg md:max-w-[50%] md:mx-auto`}>
            {description}
          </p>
        )}
      </TitleBlock>
      {listType === 'Vertical' ? (
        <div className="w-[90%] mx-auto md:px-[20%]">{listItems.map(getListItemElement)}</div>
      ) : (
        <div className={`grid grid-cols-1 lg:gap-8 ${columnClass || 'lg:grid-cols-4'}`}>
          {listItems.map(getListItemElement)}
        </div>
      )}
    </Container>
  )
}

export default ListContentBlock
