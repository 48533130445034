import { useEffect, useState } from 'react'
import { getLocalizedFields } from '../../lib/locale'
import { SSR_COUNTRY } from '../../config/locale'
import { useLocation } from './LocationProvider'

export const useLocalisedContent = <T>(record: T): T => {
  const { country } = useLocation()
  const [localisedRecord, setLocalisedRecord] = useState<T>(getLocalizedFields(record, SSR_COUNTRY))

  useEffect(() => {
    setLocalisedRecord(getLocalizedFields(record, country))
  }, [country, record])

  return localisedRecord
}

export default useLocalisedContent
