import {
  TypeButton,
  TypeHeroBlock,
  TypeImageWithFocalPoint,
  TypeMarketoFormButtonBlock,
  TypeVideo,
} from '../../types/contentful'
import isDarkColor from '../../helpers/isDarkColor'
import { Icon } from '../Icon/Icon'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import VideoSlide from '../HeroSlider/VideoSlide'
import ImageSlide from '../HeroSlider/ImageSlide'
import { isContentfulType } from '../../lib/contentful'
import ReactMarkdown from 'react-markdown'
import React, { useRef } from 'react'
import { HeroBlockShapes } from '../../types/content'
import HubspotFormButtonBlock from '../MarketoFormButtonBlock/HubspotFormButtonBlock'
import Container from '../Container/Container'
import { Swiper, SwiperSlide } from 'swiper/react'
import { buildSwiperDefaultProps } from '../slider.constants'
import 'swiper/css'
import 'swiper/css/effect-fade'
import type SwiperCore from 'swiper'
import classNames from 'classnames'
import { MediaType } from '../../types/types'

type NewHeroBlockProps = {
  entry: TypeHeroBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const HeroBlockV3 = ({
  entry: {
    fields: {
      preTitle,
      title,
      backgroundColor,
      description,
      content,
      ctaButtons,
      shape,
      backgroundShape,
      fontColor,
      accentColor,
      hideDescriptionMobile,
    },
    sys,
  },
}: NewHeroBlockProps) => {
  const isDarkClassname = isDarkColor(backgroundColor) ? 'dark' : ''
  const swiperRef = useRef<SwiperCore>()

  return (
    <Container
      className="full-width mt-navbar py-10 px-4 xl:px-20"
      style={fontColor ? { color: fontColor } : {}}
      id={sys.id}
    >
      <div className="max-w-[90rem] md:mx-[3rem] xl:m-auto">
        <div
          className={`absolute top-0 left-0 bottom-0 right-0 -z-10 ${
            backgroundShape === HeroBlockShapes.Razor
              ? 'razor max-w-[90rem] md:mx-[1rem] lg:mx-[3rem] xl:m-auto'
              : ''
          }`}
          style={{ background: `${backgroundColor}` }}
        ></div>

        <div
          className={`flex flex-col lg:flex-row justify-between lg:ml-10 text-center lg:text-start my-6 items-center gap-3`}
        >
          <div className="lg:w-1/2 flex flex-col gap-4 mb-12 lg:mb-0 flex-shrink-0">
            {preTitle && (
              <h2
                style={accentColor ? { color: accentColor } : {}}
                className={`text-secondary-ui-600 full-width lg:constrained-width`}
              >
                {preTitle}
              </h2>
            )}

            {title && (
              <h1 className="font-primary text-3xl full-width lg:constrained-width lg:text-5xl xl:text-6xl">
                {title}
              </h1>
            )}

            {!!description && (
              <ReactMarkdown
                className={`[&>p>a]:text-[blue] [&>p>a]:underline [&>p>a]:underline-offset-4 ${
                  hideDescriptionMobile ? 'hidden md:block' : ''
                } font-secondary font-thin text-xl mt-6`}
                linkTarget="_blank"
              >
                {description}
              </ReactMarkdown>
            )}

            {ctaButtons && (
              <div className="flex full-width lg:constrained-width item-center justify-center lg:justify-normal flex-row gap-6 mt-6 flex-wrap [&>*:first-child]:w-full md:[&>*:first-child]:w-auto">
                {ctaButtons?.map((ctaButton, index) => (
                  <div
                    key={`ctaButton-${index}`}
                    className={index > 0 ? `lg:ml-0 lg:mt-0 ${isDarkClassname}` : ''}
                  >
                    <div>
                      {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                        ctaButton,
                        'button'
                      ) &&
                        !!ctaButton?.fields?.url && (
                          <Button
                            id={ctaButton?.sys.id}
                            buttonStyle={contentfulType[ctaButton?.fields?.style]}
                            size={contentfulSize[ctaButton?.fields?.size]}
                            hoverColor={ctaButton?.fields?.hoverColor}
                            backgroundColor={
                              ctaButton.fields?.style?.toLocaleLowerCase() ===
                                contentfulType.Solid && accentColor
                                ? accentColor
                                : ctaButton?.fields?.color
                            }
                            textColor={
                              accentColor &&
                              ctaButton.fields?.style?.toLocaleLowerCase() ===
                                contentfulType.Solid &&
                              backgroundColor
                            }
                            href={ctaButton.fields?.url}
                            variant={contentfulVariant[ctaButton?.fields?.variant]}
                            urlBehaviour={ctaButton.fields?.urlBehaviour}
                          >
                            {ctaButton.fields.align !== 'Right' && ctaButton.fields.icon && (
                              <Icon className="w-5 h-5 mr-2" name={ctaButton.fields.icon} />
                            )}
                            {ctaButton.fields?.label}
                            {ctaButton.fields.align === 'Right' && ctaButton.fields.icon && (
                              <Icon className="w-5 h-5 ml-2" name={ctaButton.fields.icon} />
                            )}
                          </Button>
                        )}

                      {isContentfulType<
                        TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
                      >(ctaButton, 'marketoFormButtonBlock') && (
                        <HubspotFormButtonBlock
                          portalId={ctaButton.fields.portalId}
                          formId={ctaButton.fields.marketoFormId}
                          title={ctaButton.fields.title}
                          buttonLabel={ctaButton.fields.buttonLabel}
                          buttonColor={ctaButton.fields.buttonColor}
                          style={contentfulType[ctaButton?.fields?.style]}
                          textColor={isDarkColor(backgroundColor) ? 'white' : 'black'}
                          size={contentfulSize[ctaButton?.fields?.size]}
                          icon={ctaButton.fields.icon}
                          buttonHoverColor={ctaButton.fields.buttonHoverColor}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {content.length > 0 && (
            <Swiper
              {...buildSwiperDefaultProps({
                enabled: content.length > 1,
                horizontalClass:
                  'absolute flex justify-center z-40 !bottom-1 left-1/2 transform -translate-x-1/2',
              })}
              className={`!w-full lg:!w-auto ${content?.length > 1 ? '!pb-10' : ''}`}
              onBeforeInit={swiper => {
                swiperRef.current = swiper
              }}
              enabled={content.length > 1}
              key={`swiper-hero-block-${title}`}
            >
              {content
                ?.filter(
                  slide =>
                    (isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                      slide,
                      'video'
                    ) &&
                      slide.fields.variant === MediaType.VIDEO) ||
                    isContentfulType<TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                      slide,
                      'imageWithFocalPoint'
                    )
                )
                ?.map((slide, index) => {
                  return (
                    <SwiperSlide key={index} virtualIndex={index}>
                      {isContentfulType<TypeVideo<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                        slide,
                        'video'
                      ) ? (
                        <div className="full-width md:constrained-width self-center">
                          <div
                            className={classNames(
                              'm-auto w-[20rem] md:w-[30rem] md:h-[22rem]',
                              slide.fields.variant === 'Video' &&
                                'lg:w-[40rem] aspect-video lg:aspect-square'
                            )}
                          >
                            <VideoSlide
                              block={slide}
                              shape={shape}
                              className="relative m-auto top-0 left-0 w-[20rem] aspect-video lg:aspect-square md:w-[30rem] md:h-[22rem]"
                            />
                          </div>
                        </div>
                      ) : isContentfulType<
                          TypeImageWithFocalPoint<'WITHOUT_UNRESOLVABLE_LINKS', string>
                        >(slide, 'imageWithFocalPoint') ? (
                        <ImageSlide
                          block={slide}
                          shape={shape}
                          className="self-center relative m-auto top-0 left-0 w-[20rem] aspect-video lg:aspect-square md:w-[30rem] md:h-[22rem]"
                        />
                      ) : (
                        <></>
                      )}
                    </SwiperSlide>
                  )
                })}
              {content?.length > 1 && (
                <>
                  <Icon
                    name="ArrowLeft"
                    className="cursor-pointer absolute bottom-0 left-1/2 -translate-x-14 z-50"
                    onClick={() => swiperRef.current?.slidePrev()}
                  />
                  <Icon
                    name="ArrowRight"
                    className="cursor-pointer absolute bottom-0 left-1/2 translate-x-10 z-50"
                    onClick={() => swiperRef.current?.slideNext()}
                  />
                </>
              )}
            </Swiper>
          )}
        </div>
      </div>
    </Container>
  )
}

export default HeroBlockV3
