import React, { useState } from 'react'
import MenuBar from './MenuBar'
import { Icon } from '../Icon/Icon'
import {
  TypeNavigationMenu,
  TypeResourceSet,
  TypeMarketoFormButtonBlock,
  TypeButton,
  TypePage,
} from '../../types/contentful'
import { isContentfulType } from '../../lib/contentful'
import MenuGroup from './MenuGroup'
import Link from 'next/link'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { useRouter } from 'next/router'
import useScroll from '../../helpers/hooks/useScroll'
import { themes, navBarStyles, navBarLogoPosition } from '../../types/types'
import MarketoFormButtonBlock from '../MarketoFormButtonBlock/MarketoFormButtonBlock'
import isDarkColor from '../../helpers/isDarkColor'
import SVG from 'react-inlinesvg'

type MenuProps = {
  //values
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  // colors
  lightColor?: string
  darkColor?: string
  theme?: TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['navigationTheme']
  hoverColor?: string
  authenticated?: boolean
}

const Menu = ({
  menu,
  menu: {
    fields: {
      hoverColor,
      navBarStyle = navBarStyles.HAMBURGER,
      lightColor,
      darkColor,
      logoPosition = navBarLogoPosition.CENTER,
      resources = [],
      navbarButton,
      navbarButtons,
      darkLargeLogo,
      lightLargeLogo,
      darkSmallLogo,
      lightSmallLogo,
      homeRedirect,
      sideMenuTopIcon,
      // type,
    },
  },
  theme = themes.LIGHT,
  authenticated,
}: MenuProps) => {
  const router = useRouter()
  const [open, setOpen] = useState<boolean>(false)
  const handleMenuClick = () => setOpen(open => !open)
  const activeSlug = router.asPath
  const { scrolled, scrolledToTop } = useScroll()

  const isHome = homeRedirect && router.pathname === '/'

  const buttonIndex = authenticated ? (navbarButtons.length === 2 ? 1 : 0) : 0
  const logoSlug = isHome ? homeRedirect : '/'

  const cssVars = {
    '--dark-navbar-bg': `${darkColor}`,
    '--light-navbar-bg': `${lightColor}`,
    transitionProperty: 'background-color',
    transitionDuration: '300ms',
  } as React.CSSProperties

  let transparentLargeLogo
  let transparentSmallLogo
  let navbarBgColor
  let largeLogo
  let smallLogo

  const solid = 'bg-light-navbar dark:bg-dark-navbar'
  const transparent = scrolled && !scrolledToTop ? 'bg-light-navbar' : 'transparent'

  const darkMode =
    theme === themes.DARK
      ? 'dark'
      : theme === themes.TRANSPARENT_LIGHT && scrolledToTop
      ? 'dark'
      : ''

  switch (theme) {
    case themes.DARK:
      navbarBgColor = solid
      largeLogo = `https:${darkLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${darkSmallLogo?.fields?.file?.url}`
      break

    case themes.LIGHT:
      navbarBgColor = solid
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      break

    case themes.TRANSPARENT_LIGHT:
      navbarBgColor = transparent
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      transparentLargeLogo = `https:${darkLargeLogo?.fields?.file?.url}`
      transparentSmallLogo = `https:${darkSmallLogo?.fields?.file?.url}`
      break

    case themes.TRANSPARENT_DARK:
      navbarBgColor = transparent
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      break

    default:
      navbarBgColor = solid
      largeLogo = `https:${lightLargeLogo?.fields?.file?.url}`
      smallLogo = `https:${lightSmallLogo?.fields?.file?.url}`
      break
  }

  return (
    <>
      <div
        className={`${darkMode} fixed left-0 right-0 top-0 z-[200] max-h-[86px] overflow-hidden`}
        style={cssVars}
      >
        <div className={`${navbarBgColor}`}>
          <div className={`mx-auto px-4 sm:px-6`}>
            <div className="flex items-center py-7 justify-start md:space-x-10">
              <div className="flex flex-1 justify-start items-center gap-6">
                {navBarStyle !== navBarStyles.NOSHOW && menu?.fields?.resources?.length > 0 && (
                  <button
                    onClick={handleMenuClick}
                    className={`flex ${
                      navBarStyle === navBarStyles.HAMBURGER ? '' : 'md:hidden'
                    } justify-start items-center text-sm uppercase dark:text-primary-ui-white`}
                  >
                    <Icon
                      name={'Hamburger'}
                      className={`cursor-pointer text-secondary-ui-900 dark:text-primary-ui-white hover:text-black mr-1`}
                    />
                    Menu
                  </button>
                )}
                <div
                  className={`flex flex-shrink-0 ${
                    navBarStyle === navBarStyles.NOSHOW ? ' sm:hidden' : ' hidden'
                  }
                ${logoPosition === 'left' ? 'sm:!flex' : ''}`}
                >
                  <Link href={`${isHome ? '/browse/courses' : '/'}`}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={
                        theme === themes.TRANSPARENT_LIGHT && scrolledToTop
                          ? transparentLargeLogo
                          : largeLogo
                      }
                      alt={darkLargeLogo?.fields?.title}
                      className="max-h-8 w-auto"
                    />
                  </Link>
                </div>
                <div
                  className={`hidden ${
                    navBarStyle === navBarStyles.INLINE ? 'md:flex' : 'hidden'
                  } justify-start flex-column flex-1 ml-8 md:dark:text-primary-ui-white`}
                >
                  {resources.map(menuItem => {
                    if (
                      isContentfulType<TypeNavigationMenu<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                        menuItem,
                        'navigationMenu'
                      ) &&
                      menuItem?.fields?.title === 'Top navigation'
                    ) {
                      return (
                        <MenuGroup
                          key={menuItem.sys.id}
                          menu={menuItem}
                          activeSlug={activeSlug}
                          hoverColor={hoverColor}
                          type="navbar"
                        />
                      )
                    }
                  })}
                </div>
              </div>
              <div
                className={`hidden ${
                  logoPosition === navBarLogoPosition.CENTER ? 'sm:flex' : 'hidden'
                }`}
              >
                <Link href={logoSlug}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  {darkLargeLogo?.fields?.file.contentType === 'image/svg+xml' ? (
                    <div className={`w-auto max-h-10 md:h-10 `}>
                      <SVG
                        className="[&>*]:fill-[--dark-navbar-bg]"
                        src={`https:${darkLargeLogo?.fields?.file.url}`}
                        width="100%"
                        height="100%"
                        style={cssVars}
                      />
                    </div>
                  ) : (
                    <img
                      src={
                        theme === themes.TRANSPARENT_LIGHT && scrolledToTop
                          ? transparentLargeLogo
                          : largeLogo
                      }
                      alt={darkLargeLogo?.fields?.title}
                      className="max-h-8 w-auto"
                    />
                  )}
                </Link>
              </div>
              <div
                className={`flex sm:hidden space-x-10 md:dark:text-primary-ui-white ${
                  navBarStyle === navBarStyles.NOSHOW ? 'hidden' : ''
                }`}
              >
                <Link href={`${isHome ? '/browse/courses' : '/'}`}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={
                      theme === themes.TRANSPARENT_LIGHT && scrolledToTop
                        ? transparentSmallLogo
                        : smallLogo
                    }
                    alt={darkLargeLogo?.fields?.title}
                    className="max-h-8 w-auto"
                  />
                </Link>
              </div>
              <div className="flex flex-1 md:flex-0 lg:flex-1 justify-end">
                {isContentfulType<TypeMarketoFormButtonBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  navbarButton,
                  'marketoFormButtonBlock'
                ) && <MarketoFormButtonBlock entry={navbarButton} mobile />}
                {isContentfulType<TypeButton<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
                  navbarButtons[buttonIndex],
                  'button'
                ) && (
                  <Button
                    id={navbarButtons[buttonIndex]?.sys?.id}
                    buttonStyle={contentfulType[navbarButtons[buttonIndex]?.fields?.style]}
                    size={contentfulSize[navbarButtons[buttonIndex]?.fields?.size]}
                    href={`${
                      (
                        navbarButtons[buttonIndex] as TypeButton<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      )?.fields?.url
                    }`}
                    urlBehaviour={
                      (
                        navbarButtons[buttonIndex] as TypeButton<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      )?.fields?.urlBehaviour
                    }
                    backgroundColor={
                      (
                        navbarButtons[buttonIndex] as TypeButton<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      ).fields?.color
                    }
                    textColor={
                      (
                        navbarButtons[buttonIndex] as TypeButton<
                          'WITHOUT_UNRESOLVABLE_LINKS',
                          string
                        >
                      )?.fields?.color
                        ? isDarkColor(
                            (
                              navbarButtons[buttonIndex] as TypeButton<
                                'WITHOUT_UNRESOLVABLE_LINKS',
                                string
                              >
                            ).fields?.color
                          )
                          ? 'white'
                          : 'black'
                        : undefined
                    }
                    variant={
                      contentfulVariant[
                        (
                          navbarButtons[buttonIndex] as TypeButton<
                            'WITHOUT_UNRESOLVABLE_LINKS',
                            string
                          >
                        )?.fields?.variant
                      ]
                    }
                  >
                    <span className="flex flex-row gap-1 items-center">
                      <Icon className={'w-5 h-4'} name={navbarButtons[buttonIndex]?.fields?.icon} />

                      <span
                        className={`${
                          navbarButtons[buttonIndex]?.fields?.icon ? 'hidden md:block' : ''
                        }`}
                      >
                        {
                          (
                            navbarButtons[buttonIndex] as TypeButton<
                              'WITHOUT_UNRESOLVABLE_LINKS',
                              string
                            >
                          )?.fields?.label
                        }
                      </span>
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {navBarStyle !== navBarStyles.NOSHOW && menu?.fields?.resources?.length > 0 && (
        <div style={cssVars} className={` ${theme === themes.DARK ? 'dark' : ''}`}>
          <MenuBar
            menu={menu}
            open={open}
            onClose={() => setOpen(false)}
            activeSlug={activeSlug}
            hoverColor={hoverColor}
            sideMenuTopIcon={sideMenuTopIcon}
          />
        </div>
      )}
    </>
  )
}

export default Menu
